import React, {useEffect, useMemo, useRef, useState} from 'react'
import './Swap.scss'
import downArrow from 'assets/images/down-arrow.png'
import dollarIcon from 'assets/images/dollar-icon.png'
import CenterizedRowBetween from 'view/common/container/CenterizedRowBetween'
import CenterizedRow from 'view/common/container/CenterizedRow'
import {unwrapResult} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {
    checkSwapRouterContractAllowance,
    sendApproveTransactionForTokenIn,
    getPairByWrapTokenId,
    getAmountsIn,
    getAmountsOut,
    getCoinBalanceAndFee,
    getCoinByMicroChainId,
    getSlippage,
    getTokenBalance,
    sendSwapTransactionForExactIn,
    sendSwapTransactionForExactOut, getCoinFee, getReceipts
} from "../component/swap/thunks/swapThunks";
import {addComma, parsingFloat, removeComma} from "../../utils/numberUtil";
import {WEB3} from "../../modules/transaction";
import {accountInfo} from "../../redux/account/accountSlice";
import button from "../common/button/Button";
import {requestAddressToExtension} from "../../modules/EQExtension/utils/messageUtils";
import {useMediaQuery} from "react-responsive";
import toast from "react-hot-toast";
import VideoBackground from '../component/app/component/VideoBackground'
import {mainNet_info, selectAllWrapTokens} from "../../redux/app/appSlice";
import ExtensionListenerPrime from "../../modules/EQExtension/ExtensionListnerPrime";
import BigNumber from 'bignumber.js'

const SWAP_TYPE_EXACT_IN = 'exact-in'
const SWAP_TYPE_EXACT_OUT = 'exact-out'
export default function Swap() {
    const dispatch = useDispatch();
    const isTablet = useMediaQuery({maxWidth: 992});

    const userInfo = useSelector(accountInfo);
    const {address} = userInfo;
    const mainNet = useSelector(mainNet_info);

    const [coin, setCoin] = useState({
        icon: '',
        name: '',
        contract_address: '',
        unit: '',
        micro_chain_currency_id: 0,
    });
    const getCoin_ = async () => {
        try {
            const coinResult = unwrapResult(
                await dispatch(
                    getCoinByMicroChainId({
                        microChainId: mainNet.micro_chain_id,
                    }),
                ),
            );
            return coinResult
        } catch (e) {
            console.error(e)
        }
    };
    const getFee_ = async () => {
        try {
            const coinResult = unwrapResult(
                await dispatch(
                    getCoinFee({
                        microChainId: mainNet.micro_chain_id,
                    }),
                ),
            );
            return coinResult
        } catch (e) {
            console.error(e)
        }
    }
    const getCoinBalanceAndFee_ = async () => {
        try {
            const coinResult = unwrapResult(
                await dispatch(
                    getCoinBalanceAndFee({
                        microChainId: mainNet.micro_chain_id,
                    }),
                ),
            );
            return coinResult
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        const initCoin = async () => {
            const coinResult = await getCoin_();
            const feeResult = await getFee_()
            setCoin({...coinResult, ...feeResult})
        }
        if (mainNet?.micro_chain_id) {
            initCoin();
        }
    }, [mainNet]);
    useEffect(() => {
        const initCoin = async () => {
            const coinBalanceAndFee = await getCoinBalanceAndFee_()
            setCoin(state => {
                return {
                    ...state,
                    ...coinBalanceAndFee
                }
            })
        }
        if (mainNet?.micro_chain_id && address) {
            initCoin();
        }
    }, [mainNet, address])

    const slippage = useRef(null);
    const getSlippage_ = async () => {
        try {
            const result = unwrapResult(await dispatch(getSlippage()))
            slippage.current = result;
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        getSlippage_();
    }, [])

    const wrapTokens = useSelector(selectAllWrapTokens);

    const [isGettingAmounts, setIsGettingAmounts] = useState(false)

    const [tokenIn, setTokenIn] = useState({
        wrap_token_id: '',
        name: '',
        icon: '',
        unit: '',
        contract_address: '',
        amount: {
            float: '',
            wei: '',
        },
        value: '',
        micro_chain_id: 0,
        owner_micro_chain_currency_id: '',
        owner_micro_chain_id: '',
    })
    const onTokenInChange = ({nativeEvent}) => {
        try {
            setIsGettingAmounts(true)
            const {target} = nativeEvent
            const newAmount = removeComma(target.value)
            if (isNaN(newAmount)) {
                return;
            }
            if (newAmount === '') {
                setTokenIn(state => {
                    return {
                        ...state, amount: {float: '', wei: ''}
                    }
                })
                setTokenOut(state => {
                    return {
                        ...state, amount: {float: '', wei: ''}
                    }
                })
                return;
            }
            const newAmountFormatted = {
                float: addComma(newAmount),
                wei: WEB3.toWei(newAmount),
            }
            setSwapType(SWAP_TYPE_EXACT_IN)
            setTokenIn(state => {
                return {
                    ...state, amount: newAmountFormatted
                }
            })
            shouldGetAmounts(SWAP_TYPE_EXACT_IN, newAmountFormatted)
        }
        catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        if (wrapTokens[0] && !tokenIn.contract_address) {
            setTokenIn(state => ({
                ...state,
                ...wrapTokens[0]
            }))
        }
    }, [wrapTokens, tokenIn])
    const getTokenInBalance = async () => {
        try {
            const balanceResult = unwrapResult(await dispatch(getTokenBalance({wrapToken: tokenIn})))
            setTokenIn(state => ({...state, balance: balanceResult.balance}))
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        if (tokenIn.contract_address && address) {
            getTokenInBalance()
        }
    }, [tokenIn.contract_address, address])

    const [isTokenInApproved, setIsTokenApproved] = useState(false)
    useEffect(() => {
        const checkSwapRouterContractAllowance_ = async () => {
            try {
                setIsTokenApproved(false)
                const hasAllowed = unwrapResult(await dispatch(checkSwapRouterContractAllowance({
                    microChainId: tokenIn.micro_chain_id,
                    tokenIn,
                })))
                setIsTokenApproved(hasAllowed);
            } catch (e) {
                console.error(e)
            }
        }
        if (coin.contract_address && address && tokenIn.contract_address && tokenIn.micro_chain_id) {
            checkSwapRouterContractAllowance_()
        }
    }, [coin, address, tokenIn?.contract_address])

    const [tokenOut, setTokenOut] = useState({
        wrap_token_id: '',
        name: '',
        icon: '',
        unit: '',
        contract_address: '',
        amount: {
            float: '',
            wei: '',
        },
        value: '',
        micro_chain_id: 0,
        owner_micro_chain_currency_id: '',
        owner_micro_chain_id: '',
    })
    const onTokenOutChange = ({nativeEvent}) => {
        try {
            setIsGettingAmounts(true)
            const {target} = nativeEvent
            const newAmount = removeComma(target.value)
            if (isNaN(newAmount)) {
                return;
            }
            if (newAmount === '') {
                setTokenIn(state => {
                    return {
                        ...state, amount: {float: '', wei: ''}
                    }
                })
                setTokenOut(state => {
                    return {
                        ...state, amount: {float: '', wei: ''}
                    }
                })
                return;
            }
            const newAmountFormatted = {
                float: addComma(newAmount),
                wei: WEB3.toWei(newAmount),
            }
            setSwapType(SWAP_TYPE_EXACT_OUT)
            setTokenOut(prevState => {
                return {
                    ...prevState, amount: newAmountFormatted
                }
            })
            shouldGetAmounts(SWAP_TYPE_EXACT_OUT, newAmountFormatted)
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        const getPairs = async () => {
            try {
                const ableWrapTokens = unwrapResult(await dispatch(getPairByWrapTokenId({wrapToken: tokenIn})))
                setTokenOut({
                    ...ableWrapTokens[0],
                    amount: {
                        float: '',
                        wei: '',
                    }
                })
            } catch (e) {
                console.error(e)
            }
        }
        if (!tokenOut.contract_address && tokenIn.contract_address) {
            getPairs();
        }
    }, [tokenIn.contract_address])
    const getTokenOutBalance = async () => {
        try {
            const balanceResult = unwrapResult(await dispatch(getTokenBalance({wrapToken: tokenOut})))
            setTokenOut(state => ({...state, balance: balanceResult.balance}))
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        if (tokenOut.contract_address && address) {
            getTokenOutBalance()
        }
    }, [tokenOut.contract_address, address])

    const [swapType, setSwapType] = useState(null)

    const lastUpdatedAmount = useRef(null);
    const lastSwapType = useRef(null);
    const shouldGetAmounts = (swapTypeChanged, amount) => {
        if (!(typeof swapTypeChanged === "string")) {
            return;
        }
        if (swapTypeChanged === SWAP_TYPE_EXACT_IN) {
            lastUpdatedAmount.current = amount;
            lastSwapType.current = swapTypeChanged;
            if (!amount.wei) {
                return;
            }
            setTimeout(() => {
                if (lastSwapType.current === swapTypeChanged && lastUpdatedAmount.current.wei === amount.wei) {
                    getAmountsOut_(amount).finally(()=>{
                        setIsGettingAmounts(false)
                    })
                }
            }, 1000)
        }
        if (swapTypeChanged === SWAP_TYPE_EXACT_OUT) {
            lastUpdatedAmount.current = amount;
            lastSwapType.current = swapTypeChanged;
            if (!amount.wei) {
                return;
            }
            setTimeout(() => {
                if (lastSwapType.current === swapTypeChanged && lastUpdatedAmount.current.wei === amount.wei) {
                    getAmountsIn_(amount).finally(()=>{
                        setIsGettingAmounts(false)
                    })
                }
            }, 1000);
        }
    };

    const getAmountsIn_ = async (amount) => {
        try {
            const result = unwrapResult(await dispatch(getAmountsIn({
                amountOut: amount.wei,
                fromUnit: tokenIn.unit,
                toUnit: tokenOut.unit
            })))
            setTokenIn(state => {
                return {
                    ...state, amount: {
                        float: addComma(WEB3.fromWei(result)),
                        wei: result
                    }
                }
            })
        } catch (e) {
            console.error(e)
        }
    }
    const getAmountsOut_ = async (amount) => {
        try {
            const result = unwrapResult(await dispatch(getAmountsOut({
                amountIn: amount.wei,
                fromUnit: tokenIn.unit,
                toUnit: tokenOut.unit
            })))
            setTokenOut(state => {
                return {
                    ...state, amount: {
                        float: addComma(WEB3.fromWei(result)),
                        wei: result
                    }
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    const [isSendingTransaction, setIsSendingTransaction] = useState(false);
    const onSwap = async () => {
        try {
            setIsSendingTransaction(true)
            if (swapType === SWAP_TYPE_EXACT_IN) {
                const tokenOutMinAmount = WEB3.div(WEB3.mul(tokenOut.amount.wei, slippage.current[1] - slippage.current[0]), slippage.current[1])
                unwrapResult(await dispatch(sendSwapTransactionForExactIn({
                    tokenIn,
                    tokenOut,
                    tokenOutMinAmount,
                    swapRouterContractAddress: mainNet.swap_router_contract_address,
                    microChainId: tokenIn.micro_chain_id
                })))
                return;
            }
            if (swapType === SWAP_TYPE_EXACT_OUT) {
                const tokenInMaxAmount = WEB3.div(WEB3.mul(tokenIn.amount.wei, slippage.current[1] + slippage.current[0]), slippage.current[1])
                unwrapResult(await dispatch(sendSwapTransactionForExactOut({
                    tokenIn,
                    tokenOut,
                    tokenInMaxAmount,
                    swapRouterContractAddress: mainNet.swap_router_contract_address,
                    microChainId: tokenIn.micro_chain_id
                })))
                return;
            }
            alert('unexpected error')
        } catch (e) {
            console.error(e)
        }
    }

    const textWithSlippage = useMemo(() => {
        if (swapType === SWAP_TYPE_EXACT_IN) {
            return 'Minimum received after slippage'
        }
        if (swapType === SWAP_TYPE_EXACT_OUT) {
            return 'Maximum sent after slippage'
        }
        return 'Slippage'
    }, [swapType])
    const amountWithSlippage = useMemo(() => {
        if (swapType === SWAP_TYPE_EXACT_IN) {
            return [removeComma(tokenOut.amount.float) * (Number(slippage.current[1]) - Number(slippage.current[0])) / slippage.current[1], tokenOut.unit]
        }
        if (swapType === SWAP_TYPE_EXACT_OUT) {
            return [removeComma(tokenIn.amount.float) * (Number(slippage.current[1]) + Number(slippage.current[0])) / slippage.current[1], tokenIn.unit]
        }
        return ['-', '']
    }, [swapType, tokenOut, tokenIn])

    const [transactionResponse, setTransactionResponse] = useState(null)
    useEffect(() => {
        const getReceipts_ = async (transactionHash) => {
            try {
                const {status} = unwrapResult(await dispatch(getReceipts({transactionHash: transactionHash})))
                if(status){
                    toast.success('Request transaction success')
                } else{
                    toast.success('Request transaction fail')
                }
            } catch (e) {
                console.error(e)
            }
        }
        if (transactionResponse?.success) {
            toast.success('Request transaction in process...')
            getReceipts_(transactionResponse.data)
                .then(() => {
                    window.location.reload()
                    setTokenOut(state => {
                        return {
                            ...state,
                            amount: {
                                float: '',
                                wei: '',
                            }
                        }
                    })
                    setTokenIn(state => {
                        return {
                            ...state,
                            amount: {
                                float: '',
                                wei: '',
                            }
                        }
                    })
                    setIsSendingTransaction(false)
                    getTokenInBalance()
                    getTokenOutBalance()
                })
        } else {
            setIsSendingTransaction(false)
        }
    }, [transactionResponse])

    const loading = useMemo(()=>{
        if(!tokenIn.contract_address){
            return true
        }
        if(!tokenOut.contract_address){
            return true
        }
        if(!coin.contract_address){
            return true
        }
        if(!slippage.current){
            return true
        }
        if(!mainNet.swap_router_contract_address){
            return true
        }
        if(isSendingTransaction){
            return true
        }
        return false
    },[tokenIn.contract_address, tokenOut.contract_address, coin, mainNet,isSendingTransaction]);

    const canReplaceTokenInAndOut = useMemo(()=>{
        if(isGettingAmounts){
            return false;
        }
        return true;
    },[isGettingAmounts])
    const onReplaceTokenInAndOut = () => {
        if(!canReplaceTokenInAndOut){
            return;
        }
        const tokenInCopied = {...tokenIn};
        const tokenOutCopied = {...tokenOut};
        setTokenIn(tokenOutCopied)
        setTokenOut(tokenInCopied)
    }

    const SwapButton = useMemo(() => {
        if (!address) {
            const connectWallet = () => {
                if (process.env.REACT_APP_TARGET === 'volare') {
                    requestAddressToExtension()
                } else {
                    toast('Coming soon!', {icon: '👏'})
                }
            }
            return (
                <button className={'swap-button'} onClick={connectWallet}>Connect Wallet</button>
            )
        }
        if (loading) {
            return (
                <button className={'swap-button'} disabled>Loading</button>
            )
        }
        if (!isTokenInApproved) {
            const enableSwap = async () => {
                try {
                    unwrapResult(await dispatch(sendApproveTransactionForTokenIn({
                        microChainId: tokenIn.micro_chain_id,
                        tokenIn
                    })))
                } catch (e) {
                    console.error(e)
                }
            }
            return (
                <button className={'swap-button'} onClick={enableSwap}>Enable Swap</button>
            )
        }
        if (tokenIn.amount.wei === '0' || tokenIn.amount.wei === '' || tokenOut.amount.wei === '0' || tokenOut.amount.wei === '') {
            return (
                <button className={'swap-button'} disabled>Enter Amount</button>
            )
        }
        if (WEB3.lt(coin?.balance, coin?.fee)) {
            return (
                <button className={'swap-button'} disabled>Not enough fee</button>
            )
        }
        if (!tokenIn.hasOwnProperty('balance')) {
            return (
                <button className={'swap-button'} disabled>Not available</button>
            )
        }
        if (WEB3.lt(tokenIn?.balance, tokenIn.amount.wei)) {
            return (
                <button className={'swap-button'} disabled>Not enough balance</button>
            )
        }
        return (
            <button className={'swap-button'} onClick={onSwap}>Swap</button>
        )
    }, [loading, coin, address, isTokenInApproved, tokenIn, tokenOut])

    useEffect(()=>{
        console.log(tokenIn)
    },[tokenIn.icon])
    useEffect(()=>{
        console.log(tokenOut)
    },[tokenOut.icon])
    
    return (
        <div id={'swap'}>
            <ExtensionListenerPrime onGetTransactionResponce={setTransactionResponse} />
            <VideoBackground>
                <div className='swap-box'>
                    <div>
                        {!isTablet && <div className='swap-box-title'>Swap</div>}
                        <div className='swap-inner-box' style={{marginTop: !isTablet ? 30 : 0}}>
                            <CenterizedRowBetween>
                                <input type={'text'} name={'tokenInAmount'} value={tokenIn.amount.float} autoComplete='off'
                                       disabled={loading}
                                       onChange={onTokenInChange} placeholder={'0'}/>
                                <CenterizedRow className='token-selector'>
                                    <img
                                        src={tokenIn.icon || dollarIcon}
                                        alt={'selct token'}/>
                                    <div className='unit'>{tokenIn.unit}</div>
                                </CenterizedRow>
                            </CenterizedRowBetween>
                            <CenterizedRowBetween style={{marginTop: 10, fontSize: 12}}>
                                <div className='dollar'>
                                    $ {(tokenIn.amount.wei && tokenIn.value) ? addComma(new BigNumber(WEB3.fromWei(tokenIn.amount.wei)).dividedBy(new BigNumber(tokenIn.value)).toFixed(2)) : '0'}
                                </div>
                                <div style={{display: 'flex', color: '#77869D'}}>
                                    Balance:&nbsp;
                                    <p className='balance'>{addComma(parsingFloat(WEB3.fromWei(tokenIn?.balance)))} {tokenIn.unit}</p>
                                </div>
                            </CenterizedRowBetween>
                            <button className='exchange-button' onClick={onReplaceTokenInAndOut}>
                                <img src={downArrow} alt={'to'}/>
                            </button>
                        </div>
                        <div className='swap-inner-box' style={{marginTop: 10}}>
                            <CenterizedRowBetween>
                                <input type={'text'} name={'tokenOutAmount'} value={tokenOut.amount.float} autoComplete='off'
                                       disabled={loading}
                                       onChange={onTokenOutChange} placeholder={'0'}/>
                                <CenterizedRow className='token-selector'>
                                    <img
                                        src={tokenOut.icon || dollarIcon}
                                        alt={'selct token'}/>
                                    <div className='unit'>{tokenOut.unit}</div>
                                </CenterizedRow>
                            </CenterizedRowBetween>
                            <CenterizedRowBetween style={{marginTop: 10, fontSize: 12}}>
                                <div className='dollar'>
                                    $ {(tokenOut.amount.wei && tokenIn.value) ? addComma(new BigNumber(WEB3.fromWei(tokenOut.amount.wei)).dividedBy(new BigNumber(tokenOut.value)).toFixed(2)) : '0'}
                                </div>
                                <div style={{display: 'flex', color: '#77869D'}}>
                                    Balance:&nbsp;
                                    <p className='balance'>{addComma(parsingFloat(WEB3.fromWei(tokenOut?.balance)))} {tokenOut.unit}</p>
                                </div>
                            </CenterizedRowBetween>
                        </div>
                    </div>
                    <div>
                        <div className='swap-inner-box' style={{marginTop: 30}}>
                            <CenterizedRow>
                                <div className='label'>Expected Output</div>
                                <div className='value'>{tokenOut.amount.float} {tokenOut.unit}</div>
                            </CenterizedRow>
                            <div className='line'/>
                            <CenterizedRow>
                                <div className='label'>Network Fee</div>
                                <div className='value'>{WEB3.fromWei(coin?.fee)} {coin?.unit}</div>
                            </CenterizedRow>
                            <div className='line'/>
                            <CenterizedRow>
                                <div
                                    className='label'>{textWithSlippage} ({slippage.current && (slippage.current[0] / slippage.current[1]) * 100}%)
                                </div>
                                <div className='value'>{addComma(`${amountWithSlippage[0] === '-' ? '-' : new BigNumber(amountWithSlippage[0]).toFixed(18)}`)} {amountWithSlippage[1]}</div>
                            </CenterizedRow>
                        </div>
                        <div>
                            {SwapButton}
                        </div>
                    </div>
                </div>
            </VideoBackground>
        </div>
    )
}
