import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './Header.scss'
import { useDispatch, useSelector } from 'react-redux'
import { accountInfo, setAccount } from '../../../../redux/account/accountSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { requestAddressToExtension } from 'modules/EQExtension/utils/messageUtils'
import ExtensionListenerPrime from '../../../../modules/EQExtension/ExtensionListnerPrime'
import Profile from '../../account/component/Profile'
import { initSwap } from 'view/component/app/thunks/appThunks'
import { unwrapResult } from '@reduxjs/toolkit'
import { Desktop, Tablet } from '../../../../utils/ResponsiveUtil'
import menuIcon from 'assets/images/mob-header-menu.png'
import menuCloseIcon from 'assets/images/mob-header-menu-close.png'
import useScollBlock from '../../../hooks/useScollBlock'
import headerLogo from '../../../../assets/images/header-logo.png'
import toast from "react-hot-toast";

const Header = function () {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const account = useSelector(accountInfo)
  const isConnect = useMemo(() => Boolean(account?.address), [account])

  const scroll = useScollBlock();

  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const [isHeaderClicked, setIsHeaderClicked] = useState(false)

  const headerRoute = useMemo(() => {
    switch (location.pathname.split('/')[1]) {
      case 'swap':
        return 'Swap';
      case 'pool':
        return 'Pool';
      case 'faq':
        return 'Contact Us';
      default:
        return '';
    }
  }, [location])

  const headerComponent = useMemo(() => {
    if (isOpenMenu) {
      return (
        <img src={headerLogo} alt="header" className='header-logo' onClick={() => {
          navigate('/')
          setIsOpenMenu(false)
        }} />
      )
    } else if (headerRoute) {
      return (
        <div style={{ fontSize: 30, color: '#ffffff', fontWeight: 'bold' }} >{headerRoute}</div>
      )
    }
    return <div />
  }, [isOpenMenu, headerRoute])

  const connectAddress = useCallback((address) => {
    dispatch(setAccount({ address }))
  }, [dispatch])

  useEffect(() => {
    if (isOpenMenu || isOpenProfile) {
      scroll[0]();
    } else {
      scroll[1]();
    }
  }, [isOpenMenu, isOpenProfile, scroll])

  useEffect(() => {
    if (!Boolean(location.pathname.split('/')[1]) && isHeaderClicked) {
      setIsHeaderClicked(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (!isConnect) {
      setIsOpenMenu(false)
      setIsOpenProfile(false)
    }
  }, [isConnect])

  useEffect(() => {
    const type = sessionStorage.getItem('type');
    const address = sessionStorage.getItem('address');
    if (type === 'extension' && address) {
      dispatch(setAccount({ address }))
    }
  }, [dispatch])

  return (
    <>
      <Desktop>
        <header id={'header'}>
          <ExtensionListenerPrime onGetAddress={connectAddress} />
          <img src={headerLogo} alt="header" className='header-logo' onClick={() => navigate('/')} />
          <nav>
            <Link to={'/swap'} className={location.pathname.includes('/swap') ? 'active' : ''}>
              Swap
            </Link>
            <Link to={'/pool'} className={location.pathname.includes('/pool') ? 'active' : ''}>
              Pool
            </Link>
            <Link to={'/faq'} className={location.pathname.includes('/faq') ? 'active' : ''}>
              Contact Us
            </Link>
          </nav>
          {!isConnect &&
            <button className={'not-connected-button'} onClick={() => {
              if (process.env.REACT_APP_TARGET === 'volare') {
                requestAddressToExtension()
              } else {
                toast('Coming soon!', { icon: '👏' })
              }
            }}>Connect Wallet</button>
          }
          {isConnect && <div style={{ position: 'relative' }}>
            <button className={'connected-button'} onClick={() => setIsOpenProfile(true)}>{account.address.slice(0, 5)}...{account.address.slice(-5)}</button>
            {isOpenProfile && <Profile setIsOpenProfile={setIsOpenProfile} />}
          </div>
          }

        </header>
      </Desktop>
      <Tablet>
        <header id={'m-header'}>
          {isHeaderClicked ?
            <img src={headerLogo} alt="header" className='header-logo' onClick={() => {
              navigate('/')
              setIsOpenMenu(false)
            }} />
            :
            headerComponent
          }
          <button className='menu-button' onClick={() => setIsOpenMenu(prev => !prev)}>
            <img src={isOpenMenu ? menuCloseIcon : menuIcon} alt='menu' />
          </button>
        </header>
        {isOpenMenu && <div className={'menu'}>
          {isOpenProfile && <Profile setIsOpenProfile={setIsOpenProfile} />}
          <nav>
            {account.address && <a onClick={() => setIsOpenProfile(true)}>{account.address.slice(0, 5)}...{account.address.slice(-5)}</a>}
            <Link to={'/swap'} className={location.pathname.includes('/swap') ? 'active' : ''} onClick={() => setIsOpenMenu(false)}>
              Swap
            </Link>
            <Link to={'/pool'} className={location.pathname.includes('/pool') ? 'active' : ''} onClick={() => setIsOpenMenu(false)}>
              Pool
            </Link>
            <Link to={'/faq'} className={location.pathname.includes('/faq') ? 'active' : ''} onClick={() => setIsOpenMenu(false)}>
              Contact Us
            </Link>
          </nav>
        </div>}
      </Tablet>
    </>

  )
}
export default Header
