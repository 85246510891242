import { configureStore } from '@reduxjs/toolkit'
import accountReducer from './account/accountSlice'
import walletReducer from './wallet/walletSlice'
import appReducer from './app/appSlice'

export const store = configureStore({
  reducer: {
    account: accountReducer,
    wallet: walletReducer,
    app: appReducer,
  },
  devTools: process.env.NODE_ENV !== 'production'
})
