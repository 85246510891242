import {createAsyncThunk} from '@reduxjs/toolkit';
import {getQuoteFromServer} from 'server/dex/dexAPI.ts';
import {getMainNetInfoFromServer, getWrapTokensFromServer} from "../../../../server/swaps/swapsAPI";
import {getCurrencyFromServer} from "../../../../server/walletManage/walletManageAPI";
import {COIN_CONTRACT_ADDRESS} from "../../../../redux/dex/dexSlice";

export const initSwap = createAsyncThunk(
    'app/initSwap',
    async (_, {getState}) => {
        const mainNetInfo = await getMainNetInfoFromServer()
        const wrapTokens = await getWrapTokensFromServer();
        const wrapTokensWithQuote = await Promise.all(
            wrapTokens.map(async (wrapToken) => {
                const currency = await getCurrencyFromServer({
                    query: {
                        contractAddress: [wrapToken.contract_address],
                        microChainId: wrapToken.micro_chain_id,
                    },
                })
                const quotes = await getQuoteFromServer({
                    query: {toUnit: wrapToken.unit}
                });
                return {
                    ...wrapToken,
                    name: currency[0]?.name,
                    icon: currency[0]?.image,
                    unit: currency[0]?.unit,
                    quote_id: quotes[0]?.quote_id,
                    value: quotes[0]?.to_value,
                    // owner: {
                    //     micro_chain_id: currency[0].origin_micro_chain_id,
                    //     network_id: currency[0].origin_network_id
                    // }
                }
            })
        )
        const coins = await getCurrencyFromServer({
            query: {
                contractAddress: [COIN_CONTRACT_ADDRESS],
                microChainId: mainNetInfo.micro_chain_id,
            },
        })

        return {
            mainNetInfo,
            wrapTokens: wrapTokensWithQuote,
            coin: coins[0],
        }
    },
);
