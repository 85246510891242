/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react'
import { getEvent, isOurSource, isRightForm } from './utils/messageUtils'
// import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
// import { setConnectedInfo } from '../../../store/redux/EQconnect/EQConnectSlice'
// import { removeTransactionResult, setRawTransactionResult } from '../../../store/redux/transaction/transactionSlice'

const ExtensionListenerPrime = ({ onGetAddress, onGetTransactionResponce }) => {
  // const dispatch = useDispatch()

  const messageEventHandler = useCallback(async (event) => {
    if (!isOurSource(event) || !isRightForm(event) || !event.data.event.includes('Response')) return
    switch (getEvent(event)) {
      case 'getAddressResponse':
        if (event.data.payload.success) {
          sessionStorage.setItem('address', event.data.payload.data.address)
          sessionStorage.setItem('type', 'extension')
          // dispatch(setConnectedInfo({
          //   address: event.data.payload.data.address,
          //   type: 'extension'
          // }))
          if (onGetAddress) {
            onGetAddress(event.data.payload.data.address)
          }
        } else {
          console.warn('login failed')
          toast.error('Failed to Connect')
        }
        break
      case 'makeTransactionResponse':
        onGetTransactionResponce(event.data.payload)
        // if (event.data.payload.success) {
        //   // toast.success('Request transaction success...')
        //   // dispatch(setRawTransactionResult({
        //   //   txHash: event.data.payload.txHash,
        //   //   data: event.data.payload.data
        //   // }))
        //   if (onGetTransactionResponce) {
        //     onGetTransactionResponce(event.data.payload)
        //   }
        // } else console.warn('send transaction failed')
        break
      default:
        console.warn('unexpected message', getEvent(event))
        break
    }
  }, [onGetAddress, onGetTransactionResponce])

  useEffect(() => {
    window.addEventListener('message', messageEventHandler)
    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }, [messageEventHandler])
  return <></>
}

export default ExtensionListenerPrime
