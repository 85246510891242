import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import './Profile.scss'
import CenterizedRow from '../../../common/container/CenterizedRow'
import { useDispatch, useSelector } from 'react-redux'
import { accountInfo, resetAccount } from '../../../../redux/account/accountSlice'
import cancelIcon from '../../../../assets/images/cancel-button.png';
import copyIcon from '../../../../assets/images/copy-icon.png';
import redirectIcon from '../../../../assets/images/redirect-icon.png';
import rightArrow from '../../../../assets/images/right-arrow.png';
import dollarIcon from '../../../../assets/images/dollar-icon.png';
import CenterizedRowBetween from 'view/common/container/CenterizedRowBetween'
import History from '../molecules/History'
import {selectAllWallet, wallet_coin} from 'redux/wallet/walletSlice'
import {addComma, parsingFloat} from 'utils/numberUtil'
import { CopyToClipboard } from "react-copy-to-clipboard";
import {WEB3} from "../../../../modules/Web3";
import {unwrapResult} from "@reduxjs/toolkit";
import {getAllBalance} from "../../wallet/thunks/walletThunk";
import toast from "react-hot-toast";

const Profile = function ({ setIsOpenProfile }) {
  const dispatch = useDispatch()
  const account = useSelector(accountInfo)
  const wallets = useSelector(selectAllWallet);
  const coin = useSelector(wallet_coin);
  useEffect(()=>{
    const getBalanceAfterConnect = async () => {
      try{
        if (account.address) {
          unwrapResult(await dispatch(getAllBalance()))
        }
      } catch (e) {

      }
    }
    getBalanceAfterConnect();
  },[dispatch, account])

  const [isHistory, setIsHistory] = useState(false)

  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpenProfile(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, setIsOpenProfile]);

  const disconnectAddress = useCallback(() => {
    dispatch(resetAccount())
    sessionStorage.removeItem('address')
    sessionStorage.removeItem('type')
  }, [dispatch])

  return (<div ref={wrapperRef} className='profile'>
    {isHistory ? <History setIsHistory={setIsHistory} setIsOpenProfile={setIsOpenProfile} /> : <>
      <div className='profile-title'>Your wallet</div>
      <button className='profile-close-button' onClick={() => setIsOpenProfile(false)}><img src={cancelIcon} alt={'close'} /></button>
      <div className='profile-address'>{account.address}</div>
      <CenterizedRow style={{ marginTop: 20 }}>
        <CopyToClipboard text={account.address} onCopy={() => toast('copy successfully',{ icon: '👏' })}>
          <button className='profile-blue-button'><CenterizedRow>Copy Address<img src={copyIcon} alt={'copy'} /></CenterizedRow></button>
        </CopyToClipboard>

        {/*<button className='profile-blue-button' style={{ marginLeft: 20 }}><CenterizedRow>View on EQ Field<img src={redirectIcon} alt={'copy'} /></CenterizedRow></button>*/}
      </CenterizedRow>
      <div className='profile-box' style={{ marginTop: 20 }}>
        <div className='profile-box-label'>Balance</div>
        {[...wallets, coin].map(wallet => <CenterizedRow key={wallet.name} className="profile-token-balance">
          <img src={wallet.icon ?? wallet.image} alt={'token'} />
          <div className='profile-token-balance-unit'>{wallet.unit}</div>
          <div className='profile-token-balance-amount'>{addComma(parsingFloat(WEB3.fromWei(wallet?.balance)))}</div>
        </CenterizedRow>)}
      </div>

      <button className='profile-box' style={{ marginTop: 10 }} onClick={() => setIsHistory(true)}>
        <CenterizedRowBetween className={'history-button'}>
          <div>History</div>
          <img src={rightArrow} alt={'close'} />
        </CenterizedRowBetween>
      </button>
      <button className='profile-box' style={{ marginTop: 10 }} onClick={disconnectAddress}>
        <div style={{ color: 'red' }}>Disconnect</div>
      </button>
    </>}

  </div>)
}
export default Profile
