import React, {useCallback, useEffect, useRef, useState} from 'react'
import './History.scss'
import CenterizedRow from '../../../common/container/CenterizedRow'
import cancelIcon from '../../../../assets/images/cancel-button.png';
import backArrow from '../../../../assets/images/back-arrow.png';
import CenterizedRowBetween from 'view/common/container/CenterizedRowBetween'
import { getDexHistoryFromServer, getLiquidityHistoryFromServer } from 'server/dex/dexAPI';
import { useSelector } from 'react-redux';
import { accountInfo } from 'redux/account/accountSlice';
import moment from 'moment';
import { fromWeiAddCommaFixed } from 'utils/numberUtil';
import { WEB3 } from 'modules/Web3';
import {useInView} from "react-intersection-observer";
import { selectAllWallet } from '../../../../redux/wallet/walletSlice';

const historyTabs = ['Swap', 'Liquidity'];
const HISTORY_LIMIT = 30;

const History = function ({ setIsHistory, setIsOpenProfile }) {
  const account = useSelector(accountInfo);

  const [histories, setHistories] = useState([])
  const [activeHistory, setActiveHistory] = useState('Swap')
  const changeActiveHistoryTab = (tab) => {
    shouldGetMoreHistory.current = true;
    setHistories([])
    setActiveHistory(tab)
  }

  const wrapTokens = useSelector(selectAllWallet);

  const shouldGetMoreHistory = useRef(true);
  const getHistory = useCallback(async () => {
    switch(activeHistory){
      case 'Swap':
        return await getDexHistoryFromServer({query: {
          address: account.address,
          limit: HISTORY_LIMIT,
          lastId: histories.length > 0 ? histories[histories.length - 1].dex_id : 0,
        }});
      case 'Liquidity':
        return await getLiquidityHistoryFromServer({query: {
          address: account.address,
          limit: HISTORY_LIMIT,
          lastId: histories.length > 0 ? histories[histories.length - 1].reserve_id : 0,
        }});
      default:
        return []
    }
  }, [account, histories, activeHistory])
  const [ref, inView] = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if(inView && shouldGetMoreHistory.current){
      getHistory().then(res => {
        if(res.length < HISTORY_LIMIT) {
          shouldGetMoreHistory.current = false;
        }
        setHistories(state => [...state, ...res])
      });
    }
  },[inView, getHistory])


  const getStatusView = useCallback((statusCode) => {
    switch (statusCode) {
      case 1:
        return <strong style={{color: '#23FF53'}}>Completed</strong>
      case 2:
        return <strong style={{color: 'orange'}}>Pending</strong>
      case 3:
        return <strong style={{color: '#FF2323'}}>Rejected</strong>
      default:
    }
  }, [])

  const renderHistory = useCallback(history => {
    switch (activeHistory) {
      case 'Swap':
        return <div key={history.dex_id} className='history-box' style={{ marginTop: 20 }}>
          <CenterizedRowBetween>
            <img src={wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.to_token_id)?.icon} alt={'token'} className='history-token-image' style={{ marginRight: 4 }} />
            <div style={{width: '100%', textAlign: 'left', fontWeight: 'normal'}}>{wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.to_token_id)?.unit}</div>
            <div >{fromWeiAddCommaFixed(history.to_token_value)}</div>
          </CenterizedRowBetween>
          <div className='line' />
          <CenterizedRowBetween className="history-info">
            <div className='history-info-label'>Date</div>
            <div className='history-info-value'>{new moment(history.updated_at).format("DD / MM / YYYY HH:mm:ss")}</div>
          </CenterizedRowBetween>
          <CenterizedRowBetween className="history-info">
            <div className='history-info-label'>Currency Amount</div>
            <div className='history-info-value'>{fromWeiAddCommaFixed(history.from_token_value)} {wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.from_token_id)?.unit}</div>
          </CenterizedRowBetween>
          <CenterizedRowBetween className="history-info">
            <div className='history-info-label'>Status</div>
            <div className='history-info-value'>{getStatusView(history.status)}</div>
          </CenterizedRowBetween>
        </div>
      case 'Liquidity':
        return <div key={history.reserve_id} className='history-box' style={{ marginTop: 20 }}>
          <CenterizedRow>
            <img src={wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.first_wrap_token_id)?.icon} alt={'token'} className='history-token-image' />
            <img src={wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.second_wrap_token_id)?.icon} alt={'token'} className='history-token-image' style={{ marginLeft: -4, marginRight: 4 }} />
            <div style={{fontWeight: 'normal'}}>{wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.first_wrap_token_id)?.unit} / {wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.second_wrap_token_id)?.unit}</div>
          </CenterizedRow>
          <div className='history-box-label'>{
            history.liquidity ?
              `Remove ${WEB3.fromWei(history.first_wrap_token_amount_min)} ${wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.first_wrap_token_id)?.unit} and ${WEB3.fromWei(history.second_wrap_token_amount_min)} ${wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.second_wrap_token_id)?.unit}`
              :
              `Add ${fromWeiAddCommaFixed(history.first_wrap_token_amount, 2)} ${wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.first_wrap_token_id)?.unit} and ${fromWeiAddCommaFixed(history.second_wrap_token_amount, 2)} ${wrapTokens?.find(wrapToken => wrapToken.wrap_token_id === history.second_wrap_token_id)?.unit}`
          }</div>
          <div className='line' />
          <CenterizedRowBetween className="history-info">
            <div className='history-info-label'>Date</div>
            <div className='history-info-value'>{new moment(history.updated_at).format("DD / MM / YYYY HH:mm:ss")}</div>
          </CenterizedRowBetween>
          <CenterizedRowBetween className="history-info">
            <div className='history-info-label'>Status</div>
            <div className='history-info-value'>{getStatusView(history.status)}</div>
          </CenterizedRowBetween>
        </div>
      default:
        return null;
    }
  }, [activeHistory, getStatusView, wrapTokens])

  return (<div className='history'>
    <div className='history-title'>History</div>
    <button className='history-back-button' onClick={() => setIsHistory(false)}><img src={backArrow} alt={'back'} /></button>
    <button className='history-close-button' onClick={() => setIsOpenProfile(false)}><img src={cancelIcon} alt={'close'} /></button>
    <CenterizedRow style={{ marginTop: 20 }}>
      {historyTabs.map(tab => <button key={`tab_${tab}`} className='history-tab-button' disabled={tab === activeHistory} onClick={()=>changeActiveHistoryTab(tab)}>{tab}</button>)}
    </CenterizedRow>
    <div className='history-scroll-view'>
      {histories.map(renderHistory)}
      <div
          ref={ref}
          style={{height: 2}}
      />
    </div>
  </div>)
}
export default History
