import { unwrapResult } from '@reduxjs/toolkit'
import React, { useCallback, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Outlet } from 'react-router-dom'
import { io } from 'socket.io-client'
import Header from './Header'
import './Layout.scss'
import {initSwap} from "../thunks/appThunks";
import {mainNet_info, updateQuotes} from "../../../../redux/app/appSlice";
import {getAllBalance} from "../../wallet/thunks/walletThunk";
import {accountInfo} from "../../../../redux/account/accountSlice";
import Footer from './Footer'

export const socket = io(process.env.REACT_APP_HUB_SERVER_SOCKET_URL, {
  autoConnect: false,
  transports: ['websocket'],
});

const Layout = function () {
  const dispatch = useDispatch();
  const account = useSelector(accountInfo);
  const mainNet = useSelector(mainNet_info);

  const init = useCallback(async () => {
    try {
      unwrapResult(await dispatch(initSwap()))
    } catch (e) {

    }
  }, [dispatch])
  useEffect(() => {init()}, [init])

  const getBalanceAfterConnect = useCallback(async () => {
    try{
      if (account.address && mainNet.micro_chain_id) {
        
        unwrapResult(await dispatch(getAllBalance()))
      }
    } catch (e) {

    }
  }, [dispatch, account, mainNet])

  useEffect(() => {getBalanceAfterConnect()},[getBalanceAfterConnect])


  const getQuoteBySocket = useCallback((res) => {
    dispatch(updateQuotes(res))
  }, [dispatch])

  useEffect(() => {
    socket.connect();
    socket.emit("joinRoom", {
      roomName: `quoteCheck`,
    })
    socket.on('quoteCheck', getQuoteBySocket)
    return () => {
      socket.off('quoteCheck', getQuoteBySocket)
      socket.disconnect();
    }
  }, [getQuoteBySocket])

  return (
    <>
      <Header />
      <div id={'layout'}>
        <Outlet />
        <Footer/>
      </div>
    </>
  )
}

export default Layout
