/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import leftArrow from '../../assets/images/left-arrow.png'
import './BoxBackButton.scss';

export default function BoxBackButton({onBack}) {
  return <button className={'box-back-button'} onClick={onBack}>
    <img src={leftArrow} alt={'back'}/>
  </button>
}
