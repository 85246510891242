import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    getAmountsInFromServer,
    getAmountsOutFromServer, getQuoteFromServer, getReserveFromServer, getSlippageFromServer,
} from "../../../../server/dex/dexAPI";
import {getCurrencyFromServer} from "../../../../server/walletManage/walletManageAPI";
import {getAbiCodeFromServer} from "../../../../server/contract/contractAPI";
import {accountInfo} from "../../../../redux/account/accountSlice";
import {requestTransactionToExtension} from "../../../../modules/EQExtension/utils/messageUtils";
import {makeTransaction} from "../../../../modules/EQExtension/utils/requestTransaction";
import {makeQuery} from "../../../../utils/queryUtil";
import {
    getCoinBalanceFromServer,
    getTransactionReceiptFromServer,
    requestCallFromServer
} from "../../../../server/request/requestAPI";
import {getMicroChainDetailFromServer} from "../../../../server/network/networkAPI";
import {WEB3} from "../../../../modules/Web3";
import {ETHERS} from "../../../../modules/transaction";
import {GetCurrencyResponse} from "../../../../server/walletManage/walletManageAPI";
import {GetCoinBalanceResponse} from "../../../../server/request/requestAPI";
import {COIN_CONTRACT_ADDRESS} from "../../../../redux/dex/dexSlice";
import {mainNet_info} from "../../../../redux/app/appSlice";
import {getSwapPairsFromServer, getWrapTokensFromServer} from "../../../../server/swaps/swapsAPI";
import { selectAllWallet } from "../../../../redux/wallet/walletSlice";
import * as Process from "process";

export const checkSwapRouterContractAllowance = createAsyncThunk(
    'dex/checkSwapRouterContractAllowance',
    async ({microChainId, tokenIn}, { getState }) => {
        const userInfo = accountInfo(getState());
        const mainNet = mainNet_info(getState());
        // const {micro_chain_detail} = await getMicroChainDetailFromServer({params: {microChainId}})
        // const {micro_chain, network} = micro_chain_detail;
        // const {load_balancer} = micro_chain;
        const tokenInAbiCode = await getAbiCodeFromServer({
            params: {
                contractAddress: tokenIn.contract_address,
            },
            query: {
                microChainId,
            }
        })
        const allowanceResult = await requestCallFromServer({
            query: {
                microChainId
            },
            data:{
                to: tokenIn.contract_address,
                data: WEB3.Contract(tokenInAbiCode).methods.allowance(userInfo.address, mainNet.swap_router_contract_address).encodeABI()
            }
        })
        if(allowanceResult['0'] === '0') {
            return false;
        }
        return true;
    },
);

export const sendApproveTransactionForTokenIn = createAsyncThunk(
    'dex/enableSwapRouterContractAllowance',
    async ({microChainId, tokenIn}, { getState }) => {
        const userInfo = accountInfo(getState());
        const mainNet = mainNet_info(getState());
        const tokenInAbiCode = await getAbiCodeFromServer({
            params: {
                contractAddress: tokenIn.contract_address,
            },
            query: {
                microChainId,
            }
        })

        requestTransactionToExtension(await makeTransaction({
            address: userInfo.address,
            microChainId: microChainId,
            contractAddress: tokenIn.contract_address,
            parameters: [
                mainNet.swap_router_contract_address,
                ETHERS.getMaxUint256()
            ],
            to: tokenIn.contract_address,
            functionName: 'approve',
            abiCode: tokenInAbiCode,
            loadBalancerURL: ''
        }))
    },
);

export const getSlippage = createAsyncThunk(
    'dex/getSlippage',
    async (_, { getState }) => {
        const slippage: GetSwapPairResponse = await getSlippageFromServer();
        let numerator = slippage[0].value;
        let denominator = 1;
        while(!Number.isInteger(numerator)){
            numerator = numerator * 10;
            denominator = denominator * 10;
        }
        return [numerator.toString(), denominator.toString()]
    },
);

export const getCoinByMicroChainId = createAsyncThunk(
    'dex/getCoinByMicroChainId',
    async ({microChainId}, {getState}) => {
        const currencyResult: GetCurrencyResponse = await getCurrencyFromServer({
            query: {microChainId, contractAddress: [COIN_CONTRACT_ADDRESS]},
        });
        return currencyResult.map(currencyFromServer => ({
            icon: currencyFromServer.image,
            name: currencyFromServer.name,
            contract_address: currencyFromServer.contract_address,
            unit: currencyFromServer.unit,
            micro_chain_currency_id: currencyFromServer.micro_chain_currency_id,
        }))[0];
    },
);

export const getCoinBalanceAndFee = createAsyncThunk(
    'dex/getCoinBalance',
    async ({microChainId}, {getState}) => {
        const address = accountInfo(getState()).address;
        const {micro_chain_detail} = await getMicroChainDetailFromServer({params: {microChainId}})
        const {micro_chain, micro_chain_setting, network} = micro_chain_detail;
        const {fee} = micro_chain_setting;
        const coinBalanceResult: GetCoinBalanceResponse =
            await getCoinBalanceFromServer({
                query: {address, microChainId},
            });
        return {
            balance: coinBalanceResult.balance,
            fee,
        };
    },
);

export const getCoinFee = createAsyncThunk(
    'dex/getCoinFee',
    async ({microChainId}, {getState}) => {
        const {micro_chain_detail} = await getMicroChainDetailFromServer({params: {microChainId}})
        const {micro_chain_setting} = micro_chain_detail;
        const {fee} = micro_chain_setting;
        return {
            fee,
        };
    },
);

export const getTokenBalance = createAsyncThunk(
    'dex/getTokenBalance',
    async ({wrapToken}, { getState }) => {
        const {address} = accountInfo(getState())
        const mainNet = mainNet_info(getState());
        const abiCode = await getAbiCodeFromServer({
            params: {
                contractAddress: wrapToken.contract_address,
            },
            query: {
                microChainId:
                mainNet.micro_chain_id,
            },
        })
        const balanceResult = await requestCallFromServer({
            query: {
                microChainId: mainNet.micro_chain_id,
            },
            data:{
                to: wrapToken.contract_address,
                data: WEB3.Contract(abiCode).methods.balanceOf(address).encodeABI()
            }
        })
        return {
            balance: balanceResult[0],
        }
    },
);

export const getPairByWrapTokenId = createAsyncThunk(
    'dex/getAllPairs',
    async ({wrapToken}, { getState }) => {
        const swapPairIds = await getSwapPairsFromServer({
            query: {
                wrapTokenId: wrapToken.wrap_token_id
            }
        });
        const ableWrapTokensBySelectedWrapToken = await getWrapTokensFromServer({
            query: {wrapTokenIdList: swapPairIds},
        });
        const ableWrapTokensWithCurrency = await Promise.all(
            ableWrapTokensBySelectedWrapToken.map(async (wrapToken) => {
                const currency = await getCurrencyFromServer({
                    query: {
                        contractAddress: [wrapToken.contract_address],
                        microChainId: wrapToken.micro_chain_id,
                    },
                })
                const quotes = await getQuoteFromServer({
                    query: {toUnit: wrapToken.unit}
                });
                return {
                    ...wrapToken,
                    name: currency[0]?.name,
                    icon: currency[0]?.image,
                    unit: currency[0]?.unit,
                    quote_id: quotes[0]?.quote_id,
                    value: quotes[0]?.to_value,
                }
            })
        )

        return ableWrapTokensWithCurrency
    },
);

export const getAmountsIn = createAsyncThunk(
    'dex/getAmountsIn',
    async ({amountOut, fromUnit, toUnit }, { getState }) => {
        const result = await getAmountsInFromServer({query:{amountOut, fromUnit, toUnit}});
        return result[0];
    },
);

export const getAmountsOut = createAsyncThunk(
    'dex/getAmountsOut',
    async ({amountIn, fromUnit, toUnit }, { getState }) => {
        const result = await getAmountsOutFromServer({query:{amountIn, fromUnit, toUnit}});
        return result[1];
    },
);

export const sendSwapTransactionForExactIn = createAsyncThunk(
    'dex/sendSwapTransactionForExactIn',
    async ({tokenIn, tokenOut, tokenOutMinAmount, swapRouterContractAddress, microChainId}, { getState }) => {
        const userInfo = accountInfo(getState());
        const abiCode = await getAbiCodeFromServer({query:{microChainId}, params: {contractAddress: swapRouterContractAddress}});
        const query = makeQuery({microChainId})
        requestTransactionToExtension(await makeTransaction({
            address: userInfo.address,
            microChainId: tokenIn.micro_chain_id,
            contractAddress: swapRouterContractAddress,
            parameters: [
                tokenIn.amount.wei,
                tokenOutMinAmount,
                [tokenIn.contract_address, tokenOut.contract_address],
                userInfo.address,
                Date.now() + 600 * 1000
            ],
            to: swapRouterContractAddress,
            functionName: 'swapExactTokensForTokens',
            abiCode: abiCode,
            transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}/api/v2/dex/out${query}`,
            additionalQuery: {
                body: {
                    walletAddress: userInfo.address,
                    firstWrapTokenId: tokenIn.wrap_token_id,
                    secondWrapTokenId: tokenOut.wrap_token_id,
                }
            },
            loadBalancerURL: '',
            // token: '2',
        }))
        return true;
    },
);

export const sendSwapTransactionForExactOut = createAsyncThunk(
    'dex/sendSwapTransactionForExactOut',
    async ({tokenIn, tokenOut, tokenInMaxAmount, swapRouterContractAddress, microChainId}, { getState }) => {
        const userInfo = accountInfo(getState());
        const abiCode = await getAbiCodeFromServer({query:{microChainId}, params: {contractAddress: swapRouterContractAddress}});
        const query = makeQuery({microChainId})
        requestTransactionToExtension(await makeTransaction({
            address: userInfo.address,
            microChainId: tokenIn.micro_chain_id,
            contractAddress: swapRouterContractAddress,
            parameters: [
                tokenOut.amount.wei,
                tokenInMaxAmount,
                [tokenIn.contract_address, tokenOut.contract_address],
                userInfo.address,
                Date.now() + 600 * 1000
            ],
            to: swapRouterContractAddress,
            functionName: 'swapTokensForExactTokens',
            abiCode: abiCode,
            transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}/api/v2/dex/in${query}`,
            additionalQuery: {
                body: {
                    walletAddress: userInfo.address,
                    firstWrapTokenId: tokenIn.wrap_token_id,
                    secondWrapTokenId: tokenOut.wrap_token_id,
                }
            },
            loadBalancerURL: '',
            // token: '2',
        }))
        return true;
    },
);

export const getReceipts = createAsyncThunk(
    'dex/getReceipts',
    async ({transactionHash}, { getState }) => {
        const result = await getTransactionReceiptFromServer({
            params: {
                transactionHash
            }
        })
        return result
    },
);
