import { createAsyncThunk } from '@reduxjs/toolkit';
import { WEB3 } from 'modules/Web3';
import { accountInfo } from 'redux/account/accountSlice';
import {getCoinBalanceFromServer, requestCallFromServer} from 'server/request/requestAPI.ts';
import {getAbiCodeFromServer} from "../../../../server/contract/contractAPI";
import {mainNet_info} from "../../../../redux/app/appSlice";
import {selectAllWallet, wallet_coin} from "../../../../redux/wallet/walletSlice";

export const getAllBalance = createAsyncThunk(
    'wallet/getAllBalance',
    async (_, { getState }) => {
        const {address} = accountInfo(getState())
        const mainNet = mainNet_info(getState());
        const wallets = selectAllWallet(getState());
        const coinFromWallet = wallet_coin(getState())
        const walletsWithBalance = await Promise.all(wallets.map(async el => {
            const abiCode = await getAbiCodeFromServer({
                params: {
                    contractAddress: el.contract_address,
                },
                query: {
                    microChainId:
                    mainNet.micro_chain_id,
                },
            })
            const balanceResult = await requestCallFromServer({
                query: {
                    microChainId: mainNet.micro_chain_id,
                },
                data:{
                    to: el.contract_address,
                    data: WEB3.Contract(abiCode).methods.balanceOf(address).encodeABI()
                }
            })
            return {
                ...el,
                balance: balanceResult[0]
            }
        }))

        const coinBalanceResponse = await getCoinBalanceFromServer({
            query: {
                microChainId: mainNet.micro_chain_id,
                address
            }
        })
        const coinWithBalance = {...coinFromWallet, balance: coinBalanceResponse.balance}
        return {
            wrapTokens: walletsWithBalance,
            coin: coinWithBalance,
        }
    },
);

