import React from 'react';
import './Footer.scss'
const Footer = function () {

    return (
        <footer className='footer'>
            <div className="footer-wrapper">
                <div className="footer-link" onClick={() => window.open('https://s3.ap-northeast-2.amazonaws.com/cs-files.eqbr.com/SWAP/ACROSWAP+Privacy+Policy.pdf')}>
                    Privacy Policy
                </div>
                <div className="footer-link" onClick={() => window.open('https://s3.ap-northeast-2.amazonaws.com/cs-files.eqbr.com/SWAP/ACROSWAP+Terms+of+Service.pdf')}>
                    Terms of service
                </div>
            </div>
        </footer>
    )
}

export default Footer;
