import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import RootRouter from './view/RootRouter'
import {Toaster} from "react-hot-toast";

export default function App () {
  return (
    <BrowserRouter>
      <RootRouter/>
        <Toaster/>
    </BrowserRouter>
  )
}
