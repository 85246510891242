/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountInfo } from '../../../../redux/account/accountSlice';
import './AddLiquidityPrime.scss'
import { useMediaQuery } from 'react-responsive';
import BigNumber from 'bignumber.js';
import { unwrapResult } from '@reduxjs/toolkit';
import { WEB3 } from 'modules/Web3'
import ExtensionListenerPrime from 'modules/EQExtension/ExtensionListnerPrime'
import toast from "react-hot-toast";
import { requestAddressToExtension } from '../../../../modules/EQExtension/utils/messageUtils'
import CenterizedRowBetween from '../../../common/container/CenterizedRowBetween';
import { POOL_MAIN } from '../../../page/Pool';
import BoxBackButton from '../../../atom/BoxBackButton';
import { addComma, exceptNaN, parsingFloat, removeComma } from '../../../../utils/numberUtil';
import TokenItem from '../../../atom/TokenItem';
import {
    getTokenBalance,
    getTokenDataForAdd,
    sendAddLiquidityTransaction,
    sendApproveTransactionByIndex
} from "../thunks/poolThunks";
import { selectAllWrapTokens } from '../../../../redux/app/appSlice';

export default function AddLiquidityPrime({ setComponent }) {
    const dispatch = useDispatch();
    const account = useSelector(accountInfo)
    const isTablet = useMediaQuery({ maxWidth: 992 });
    const wrapTokens = useSelector(selectAllWrapTokens);

    const pair = useMemo(() => ({
        first_token: wrapTokens[0],
        second_token: wrapTokens[1],
    }), [wrapTokens])

    const [tokens, setTokens] = useState([])

    const [inputData, setInputData] = useState({
        tokenBalance1: '0',
        tokenBalance2: '0',
        tokenBalance1ToShow: '',
        tokenBalance2ToShow: '',
    })

    const [balances, setBalaces] = useState(['0', '0'])

    const getWrapTokenWithBalanceData = useCallback(async () => {
        try {
            const data = await Promise.all([unwrapResult(await dispatch(getTokenBalance({ wrapToken: pair.first_token }))).balance, unwrapResult(await dispatch(getTokenBalance({ wrapToken: pair.second_token }))).balance])
            setBalaces(data)
        } catch (e) {
            console.error(e)
        }
    }, [dispatch, pair])

    useEffect(() => {
        getWrapTokenWithBalanceData()
    }, [getWrapTokenWithBalanceData])

    const inputHandler = useCallback(({ nativeEvent }) => {
        try{
            const { name, value } = nativeEvent.target
            if (value.length === 0) {
                switch (name) {
                    case 'tokenBalance1':
                        setInputData(prev => ({
                            ...prev,
                            tokenBalance1: '0',
                            tokenBalance1ToShow: '',
                        }))
                        return
                    case 'tokenBalance2':
                        setInputData(prev => ({
                            ...prev,
                            tokenBalance2: '0',
                            tokenBalance2ToShow: '',
                        }))
                        return
                    default:
                }
            }
            const removeCommaValue = removeComma(value)
            if (isNaN(removeCommaValue)) {
                return;
            }
            const valueWei = WEB3.toWei(removeCommaValue)
            switch (name) {
                case 'tokenBalance1':
                    setInputData(prev => ({
                        ...prev,
                        tokenBalance1: valueWei,
                        tokenBalance1ToShow: addComma(removeCommaValue),
                    }))
                    return
                case 'tokenBalance2':
                    setInputData(prev => ({
                        ...prev,
                        tokenBalance2: valueWei,
                        tokenBalance2ToShow: addComma(removeCommaValue),
                    }))
                    return
                default:
                    return 
            }
        }catch(e){}
    }, [])

    const init = useCallback(async () => {
        const data = unwrapResult(await dispatch(getTokenDataForAdd({ pair })))
        setTokens(data)
    }, [dispatch, pair])

    useEffect(() => {
        if (!pair.first_token.contract_address || !account.address) {
            return
        }
        init();
    }, [pair, account, init])

    const onClickApprove = useCallback(async (index) => {
        try {
            unwrapResult(await dispatch(sendApproveTransactionByIndex({tokens, index})))
        } catch (e) {
            console.error(e)
        }
    }, [dispatch, tokens])

    const onClickAdd = useCallback(async () => {
        try {
            unwrapResult(await dispatch(sendAddLiquidityTransaction({pair, inputData})))
        } catch (e) {
            console.error(e)
        }
    }, [dispatch, pair, inputData])

    const activeButton = useMemo(() => {
        if (!tokens.length) {
            return <button className='add-liquidity-button' disabled={true}>
                Loading...
            </button>
        }
        if (!account.address) {
            return <button className='add-liquidity-button'
                onClick={() => {
                    if (process.env.REACT_APP_TARGET === 'volare') {
                        requestAddressToExtension()
                    } else {
                        toast('Coming soon!', { icon: '👏' })
                    }
                }}>
                Connect Wallet
            </button>
        }
        const needToApproveToken1 = WEB3.lte(tokens[0]?.allowance, inputData.tokenBalance1)
        const needToApproveToken2 = WEB3.lte(tokens[1]?.allowance, inputData.tokenBalance2)
        if (needToApproveToken1) {
            return <button className='add-liquidity-button' onClick={() => onClickApprove(0)}>
                Approve to deposit {tokens[0]?.unit}
            </button>
        } else if (needToApproveToken2) {
            return <button className='add-liquidity-button' onClick={() => onClickApprove(1)}>
                Approve to deposit {tokens[1]?.unit}
            </button>
        } else if (WEB3.gt(inputData.tokenBalance1, balances[0]) || WEB3.gt(inputData.tokenBalance2, balances[1])) {
            return <button className='add-liquidity-button' onClick={onClickAdd} disabled={true}>
                Not enough balance
            </button>
        } else {
            return <button className='add-liquidity-button' onClick={onClickAdd} disabled={(!inputData.tokenBalance1 && !inputData.tokenBalance2)}>
                Enter an amount
            </button>
        }
    }, [tokens, onClickApprove, onClickAdd, inputData, account, balances])

    const calculatedValues = useMemo(() => {
        if (pair && inputData.tokenBalance1 !== '0' && inputData.tokenBalance2 !== '0') {
            return {
                rateBA: new BigNumber(WEB3.fromWei(inputData.tokenBalance1)).dividedBy(new BigNumber(WEB3.fromWei(inputData.tokenBalance2))).toFixed(18),
                rateAB: new BigNumber(WEB3.fromWei(inputData.tokenBalance2)).dividedBy(new BigNumber(WEB3.fromWei(inputData.tokenBalance1))).toFixed(18),
            }
        }
        return {}
    }, [pair, inputData])

    const [transactionResponse, setTransactionResponse] = useState(null)

    useEffect(() => {
        if (transactionResponse?.success) {
            toast.success('Request transaction success...')
            setComponent({ name: POOL_MAIN, params: { txHash: transactionResponse.data } })
        }
    }, [transactionResponse, setComponent])

    return (
        <>
            <ExtensionListenerPrime onGetTransactionResponce={setTransactionResponse} />
            <div className={'add-liquidity'}>
                <div style={{ position: 'absolute', left: 0, top: 0 }}>
                    <BoxBackButton onBack={() => setComponent({ name: POOL_MAIN })} />
                </div>
                <h2 className='remove-liquidity-title'>Add liquidity prime</h2>
                <div style={{ marginTop: 20 }}>
                    <div className='add-liquidity-token-box' style={{ marginTop: !isTablet ? 30 : 0 }}>
                        <CenterizedRowBetween>
                            <input type={'text'} name={'tokenBalance1'} value={inputData.tokenBalance1ToShow}
                                readOnly={tokens.length === 0}
                                autoComplete='off'
                                onChange={inputHandler} placeholder={'0'} />
                            <TokenItem token={tokens[0]} />

                        </CenterizedRowBetween>
                        <CenterizedRowBetween className={'add-liquidity-token-box-bottom-row'}>
                            <div className='dollar'>
                                $ {exceptNaN(new BigNumber(WEB3.fromWei(inputData.tokenBalance1)).dividedBy(new BigNumber(pair.first_token.value)).toFixed(2))}
                            </div>
                            <div style={{ display: 'flex' }}>Balance:&nbsp;<p
                                className='balance'>{addComma(parsingFloat(WEB3.fromWei(balances[0])))} {pair.first_token.unit ?? '-'}</p>
                            </div>
                        </CenterizedRowBetween>
                    </div>
                    <div className='add-liquidity-token-box' style={{ marginTop: 15 }}>
                        <CenterizedRowBetween>
                            <input type={'text'} name={'tokenBalance2'} value={inputData.tokenBalance2ToShow}
                                readOnly={tokens.length === 0}
                                autoComplete='off'
                                onChange={inputHandler} placeholder={'0'} />
                            <TokenItem token={tokens[1]} />
                        </CenterizedRowBetween>
                        <CenterizedRowBetween className={'add-liquidity-token-box-bottom-row'}>
                            <div className='dollar'>
                                $ {exceptNaN(new BigNumber(WEB3.fromWei(inputData.tokenBalance2)).dividedBy(new BigNumber(pair.second_token.value)).toFixed(2))}
                            </div>
                            <div style={{ display: 'flex' }}>Balance:&nbsp;<p
                                className='balance'>{addComma(parsingFloat(WEB3.fromWei(balances[1])))} {pair.second_token.unit ?? '-'}</p>
                            </div>
                        </CenterizedRowBetween>
                    </div>
                    <div style={{ fontSize: 14, margin: '20px 0', lineHeight: '18px', letterSpacing: '0.8px', padding: '0 14px', color: '#77869D' }}>
                        By adding liquidity you’ll earn 0.3% of all trades on this pair proportional to your share of the pool 🙌
                    </div>
                </div>
                
                {calculatedValues.rateAB &&
                    <div className='add-liquidity-token-box' style={{ marginTop: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='add-liquidity-token-box-label'>Price</div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <div className='add-liquidity-token-box-value'>1 {pair.first_token.unit} = {addComma(calculatedValues.rateAB)} {pair.second_token.unit}</div>
                                <div className='add-liquidity-token-box-value'
                                    style={{ marginTop: 10 }}>1 {pair.second_token.unit} = {addComma(calculatedValues.rateBA)} {pair.first_token.unit}</div>
                            </div>
                        </div>
                        <div className='line' />
                        <CenterizedRowBetween>
                            <div className='add-liquidity-token-box-label'>Share Value</div>
                            <div className='add-liquidity-token-box-value'>100 %
                            </div>
                        </CenterizedRowBetween>
                        <div className='line' />
                        <CenterizedRowBetween>
                            <div className='add-liquidity-token-box-label'>Fee</div>
                            <div className='add-liquidity-token-box-value'>{0}</div>
                        </CenterizedRowBetween>

                    </div>
                }
                {activeButton}
            </div>
        </>
    )
}
