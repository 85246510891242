import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {initSwap} from "../../view/component/app/thunks/appThunks";
import {getAllBalance} from "../../view/component/wallet/thunks/walletThunk";

// Adapter 영역 - 선언부
const walletAdapter = createEntityAdapter({
  selectId: token => token.wrap_token_id,
});
export const {
  selectAll: selectAllWallet,
  selectIds: selectWalletIds,
  selectById: selectWalletById,
} = walletAdapter.getSelectors(
    state => state.wallet.wrapTokens,
);

// Slice 영역 - 선언부
const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    wrapTokens: walletAdapter.getInitialState(),
    coin: {}
  },
  reducers: {},
  extraReducers: {
    [initSwap.fulfilled]: (state, action) => {
      walletAdapter.setMany(state.wrapTokens, action.payload.wrapTokens);
      state.coin = action.payload.coin;
    },
    [getAllBalance.fulfilled]: (state, action) => {
      walletAdapter.updateMany(state.wrapTokens, action.payload.wrapTokens.map(el => ({id: el.wrap_token_id, changes: el})));
      state.coin = action.payload.coin
    },
  }
})

// Action 영역

// Store 데이터 영역
export const wallet_coin = state => state.wallet.coin

// Reducer 영역
export default walletSlice.reducer
