import React, {useCallback, useEffect, useState} from 'react'
import './Pool.scss'
import PoolMain from '../component/pool/component/PoolMain';
import AddLiquidity from '../component/pool/component/AddLiquidity';
import RemoveLiquidity from '../component/pool/component/RemoveLiquidity';
import VideoBackground from '../component/app/component/VideoBackground';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { accountInfo } from '../../redux/account/accountSlice';
import { selectAllWallet } from '../../redux/wallet/walletSlice';
import {getReserve,getReceipts} from "../component/pool/thunks/poolThunks";
import toast from "react-hot-toast";
import AddLiquidityPrime from '../component/pool/component/AddLiquidityPrime';

export const POOL_MAIN = 'POOL_MAIN';
export const POOL_ADD_LIQUIDITY = 'POOL_ADD_LIQUIDITY';
export const POOL_ADD_LIQUIDITY_PRIME = 'POOL_ADD_LIQUIDITY_PRIME';
export const POOL_REMOVE_LIQUIDITY = 'POOL_REMOVE_LIQUIDITY';

export default function Pool() {
  const dispatch = useDispatch()
  const account = useSelector(accountInfo)
  const wrapTokens = useSelector(selectAllWallet)

  const [component, setComponent] = useState({
    name: POOL_MAIN,
    params: {}
  })

  const [pairs, setPairs] = useState();
  const getReserveData = useCallback(async () => {
    const reserveData = unwrapResult(await dispatch(getReserve()))
    setPairs(reserveData)
    return reserveData
  }, [dispatch])
  useEffect(() => {
    if(wrapTokens.length > 0){
      getReserveData()
    }
  }, [getReserveData, account, wrapTokens])

  useEffect(() => {
    const getReceipts_ = async (transactionHash) => {
      try {
        const {status} = unwrapResult(await dispatch(getReceipts({transactionHash: transactionHash})))
        if(status){
          toast.success('Request transaction success')
        } else{
          toast.success('Request transaction fail')
        }
      } catch (e) {
        console.error(e)
      }
    }
    if(component.name === POOL_MAIN && component?.params?.txHash){
      getReceipts_(component?.params?.txHash)
      .finally(() => {
        window.location.reload()
        setComponent(state => ({...state, params: {}}))
      })
    }
  }, [dispatch, component])

  return (

    <div id={'pool'}>
      <VideoBackground>
        <div className='pool-box'>
          {component.name === POOL_MAIN && <PoolMain setComponent={setComponent} component={component} pairs={pairs} getReserveData={getReserveData} />}
          {component.name === POOL_ADD_LIQUIDITY_PRIME && <AddLiquidityPrime setComponent={setComponent} component={component} />}
          {component.name === POOL_ADD_LIQUIDITY && <AddLiquidity setComponent={setComponent} component={component} />}
          {component.name === POOL_REMOVE_LIQUIDITY && <RemoveLiquidity setComponent={setComponent} component={component} />}
        </div>
      </VideoBackground>
    </div>
  )
}
