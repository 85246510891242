//todo
// Response 영역에서 null 일 수 있는 값들의 확인 필요
import {networkServer} from './networkServer';
import { makeQuery } from '../../utils/queryUtil';

const getNetworks_v1 = async (
  request: GetNetworksRequest_v1,
): Promise<GetNetworksResponse_v1> =>
  await networkServer.get({
    path: '/api/v1/networks/',
    query: makeQuery(request.query),
  });
export type GetNetworksRequest_v1 = {
  query: GetNetworksQuery_v1;
};
type GetNetworksQuery_v1 = {
  networkIds?: number[];
  ownerId?: number;
  mainStatus?: number[];
  subStatus?: number[];
  external?: boolean;
};
export type GetNetworksResponse_v1 = NetworkShort_v1[];
type NetworkShort_v1 = {
  id: number;
  label: string;
  owner_id: number;
  version?: string;
  chain_network_id: string;
  main_status: number;
  sub_status: number;
  created_at: string;
  updated_at: string;
  fee: string;
};

const getNetworks_v2 = async (
  request: GetNetworksRequest_v2,
): Promise<GetNetworksResponse_v2> =>
  await networkServer.get({
    path: '/api/v2/networks/',
    query: makeQuery(request.query),
  });
export type GetNetworksRequest_v2 = {
  query: GetNetworksQuery_v2;
};
type GetNetworksQuery_v2 = {
  networkIds?: number[];
  ownerId?: number;
  mainStatus?: number[];
  subStatus?: number[];
  external?: boolean;
};
export type GetNetworksResponse_v2 = NetworkShort_v2[];
type NetworkShort_v2 = {
  id: number;
  label: string;
  owner_id: number;
  version?: string;
  is_private: boolean;
  main_status: number;
  sub_status: number;
  created_at: string;
  updated_at: string;
};

const getMicroChains_v1 = async (
  request: GetMicroChainsRequest_v1,
): Promise<GetMicroChainsResponse_v1> =>
  await networkServer.get({
    path: '/api/v1/micro-chains/',
    query: makeQuery(request.query),
  });
export type GetMicroChainsRequest_v1 = {
  query: GetMicroChainsQuery_v1;
};
type GetMicroChainsQuery_v1 = {
  networkId?: number;
  networkOwnerId?: number;
  microChainIds?: number[];
  ownerId?: number;
  isMain?: boolean;
  mainStatus: number[];
  subStatus: number[];
  external: boolean;
};
export type GetMicroChainsResponse_v1 = MicroChainShort_v1[];
type MicroChainShort_v1 = {
  id: number;
  network_id: number;
  owner_id: number;
  is_main: boolean;
  version: string;
  chain_id: string;
  main_status: number;
  sub_status: number;
  create_at: string;
  updated_at: string;
  network: object;
  fee: string;
};

const getMicroChains_v2 = async (
  request: GetMicroChainsRequest_v2,
): Promise<GetMicroChainsResponse_v2> =>
  await networkServer.get({
    path: '/api/v2/micro-chains/',
    query: makeQuery(request.query),
  });
export type GetMicroChainsRequest_v2 = {
  query: GetMicroChainsQuery_v2;
};
type GetMicroChainsQuery_v2 = {
  networkId?: number;
  networkOwnerId?: number;
  microChainIds?: number[];
  ownerId?: number;
  isMain?: boolean;
  mainStatus: number[];
  subStatus: number[];
  external: boolean;
};
export type GetMicroChainsResponse_v2 = MicroChainShort_v2[];
type MicroChainShort_v2 = {
  id: number;
  network_id: number;
  label: string;
  owner_id: number;
  is_main: boolean;
  version?: string;
  main_status: number;
  sub_status: number;
  create_at: string;
  updated_at: string;
  fee: string;
  gas_price?: string;
  request_server_ip?: string;
  network: object;
};

const getNetworkDetail = async (
  request: GetNetworkDetailRequest,
): Promise<GetNetworkDetailResponse> =>
  await networkServer.get({
    path: `/api/v2/networks/${request.params.networkId}`,
  });
export type GetNetworkDetailRequest = {
  params: GetNetworkDetailParams;
};
type GetNetworkDetailParams = {
  networkId: number;
};
export type GetNetworkDetailResponse = {
  network_detail: {
    micro_chain: {
      external_micro_chain_id: string;
      id: number;
      is_main: boolean;
      label: string;
      owner_id: number;
    };
    network: {
      id: number;
      external_network_id: string;
      label: string;
      owner_id: number;
    };
  };
};

const getMicroChainDetail = async (
  request: GetMicroChainDetailRequest,
): Promise<GetMicroChainDetailResponse> =>
  await networkServer.get({
    path: `/api/v2/micro-chains/${request.params.microChainId}`,
  });
export type GetMicroChainDetailRequest = {
  params: GetMicroChainDetailParams;
};
type GetMicroChainDetailParams = {
  microChainId: number;
};
export type GetMicroChainDetailResponse = {
  micro_chain_detail: {
    network: {
      external_network_id?: string;
      id: number;
      label: string;
      owner_id: number;
    };
    micro_chain: {
      external_micro_chain_id?: string;
      id: number;
      is_main: boolean;
      label: string;
      owner_id: number;
      load_balancer: string;
    };
  };
};

const getMicroChainCurrency = async (
  request: GetMicroChainCurrencyRequest,
): Promise<GetMicroChainCurrencyResponse> =>
  await networkServer.get({
    path: '/api/v2/micro-chain-currency',
    query: request?.query,
  });
export type GetMicroChainCurrencyRequest = {
  query: GetMicroChainCurrencyQuery;
};
type GetMicroChainCurrencyQuery = {
  microChainId: number;
  microChainCurrencyIds: number[];
  type: number[];
  originOwnerId: number;
  contractAddress: string[];
};
export type GetMicroChainCurrencyResponse = MicroChainCurrency[];
type MicroChainCurrency = {
  micro_chain_currency_id: number;
  micro_chain_id: number;
  contract_id: number;
  contract_address: string;
  type: number;
  currency_id: number;
  name: string;
  image: string;
  unit: string;
  origin_network_id: number;
  origin_micro_chain_id: number;
  origin_owner_id: number;
};

export {
  getNetworkDetail as getNetworkDetailFromServer,
  getMicroChainDetail as getMicroChainDetailFromServer,
  getMicroChainCurrency as getMicroChainCurrencyFromServer,
  getNetworks_v1 as getNetworksV1FromServer,
  getNetworks_v2 as getNetworksV2FromServer,
  getMicroChains_v1 as getMicroChainsV1FromServer,
  getMicroChains_v2 as getMicroChainsV2FromServer,
};
