// hk add
const nacl = require('tweetnacl')
const CryptoJS = require('crypto-js')

var KEY = KEY || {}

KEY.HexToByte = function (str) {
  if (!str) {
    return new Uint8Array()
  }

  const a = []
  for (let i = 0, len = str.length; i < len; i += 2) {
    a.push(parseInt(str.substr(i, 2), 16))
  }

  return new Uint8Array(a)
}

KEY.ByteToHex = function (byte) {
  if (!byte) {
    return ''
  }

  let hexStr = ''
  for (let i = 0; i < byte.length; i++) {
    let hex = (byte[i] & 0xff).toString(16)
    hex = (hex.length === 1) ? '0' + hex : hex
    hexStr += hex
  }

  return hexStr.toLowerCase()
}

KEY.StringToByte = function (str) {
  const buf = new Uint8Array(new ArrayBuffer(str.length))
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    buf[i] = str.charCodeAt(i)
  }
  return buf
}

KEY.ByteToString = function (buf) {
  const arrayBuffer = new Uint8Array(buf)
  const s = String.fromCharCode.apply(null, arrayBuffer)
  return decodeURIComponent(s)
}

KEY.MakeThash = function (str) {
  if (typeof str !== 'string') {
    str = JSON.stringify(str)
  }

  // return CryptoJS.SHA256(str).toString(CryptoJS.enc.Base64);
  return CryptoJS.SHA256(str).toString()
}

KEY.MakeKeypair = function () {
  let keypair = nacl.sign.keyPair()
  const private_key = this.ByteToHex(keypair.secretKey).substr(0, 64)
  const public_key = this.ByteToHex(keypair.publicKey)

  keypair = {
    PublicKey: public_key,
    PrivateKey: private_key
  }

  return keypair
}

KEY.MakeAddress = function (public_key) {
  const p0 = '0x00'
  const p1 = '0x1k'
  let address, s0, s1, s2

  s0 = CryptoJS.SHA256(p0 + public_key).toString()
  s0 = CryptoJS.RIPEMD160(s0).toString()
  s1 = p1 + s0

  s2 = CryptoJS.SHA256(s1).toString()
  s2 = CryptoJS.SHA256(s2).toString()

  address = s1 + s2.substr(0, 4)

  return address
}

KEY.MakeSignature = function (str, private_key, public_key) {
  private_key = private_key + public_key

  return this.ByteToHex(nacl.sign.detached(this.StringToByte(str), this.HexToByte(private_key)))
}

KEY.ValidSignature = function (str, public_key, signature) {
  return nacl.sign.detached.verify(this.StringToByte(str), this.HexToByte(signature), this.HexToByte(public_key))
}

KEY.MakePublicKey = function (private_key) {
  return this.ByteToHex(nacl.sign.keyPair.fromSeed(this.HexToByte(private_key)).publicKey).substr(0, 64)
}

KEY.MakeBoxKeypair = function () {
  const BoxKeypair = nacl.box.keyPair()
  const BoxPrivate_key = this.ByteToHex(BoxKeypair.secretKey)
  const BoxPublic_key = this.ByteToHex(BoxKeypair.publicKey)

  const keypair = {
    BoxPrivateKey: BoxPrivate_key,
    BoxPublicKey: BoxPublic_key
  }

  return keypair
}

KEY.MakeBoxPublicKey = function (privateKey) {
  return this.ByteToHex(nacl.box.keyPair.fromSecretKey(this.HexToByte(privateKey)).publicKey)
}

KEY.MakeBoxKeypair = function () {
  const BoxKeypair = nacl.box.keyPair()
  const BoxPrivate_key = this.ByteToHex(BoxKeypair.secretKey)
  const BoxPublic_key = this.ByteToHex(BoxKeypair.publicKey)

  const keypair = {
    BoxPrivateKey: BoxPrivate_key,
    BoxPublicKey: BoxPublic_key
  }

  return keypair
}

KEY.Encryption = function (message, my_box_private_key, their_box_public_key) {
  const nonce = nacl.randomBytes(nacl.box.nonceLength)
  const val = {
    box: KEY.ByteToHex(nacl.box(KEY.StringToByte(message), nonce, this.HexToByte(their_box_public_key), this.HexToByte(my_box_private_key))),
    nonce
  }
  return val
}

KEY.Decryption = function (box, nonce, my_box_private_key, their_box_public_key) {
  box = this.HexToByte(box)
  return this.ByteToString(nacl.box.open(box, nonce, this.HexToByte(their_box_public_key), this.HexToByte(my_box_private_key)))
}

module.exports = KEY
