import React, { useCallback, useState } from 'react'
import './Contact.scss'
import { useMediaQuery } from "react-responsive";
import VideoBackground from '../component/app/component/VideoBackground';
import { postDexQNAFromServer } from '../../server/dex/dexAPI';
import toast from 'react-hot-toast';

const initialInputData = {
  email: '',
  title: '',
  content: '',
}

export default function Contact() {
  const isTablet = useMediaQuery({ maxWidth: 992 });

  const [inputData, setInputData] = useState(initialInputData)

  const inputHandler = useCallback(({ nativeEvent }) => {
    const { name, value } = nativeEvent.target
    setInputData(prev => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const sendInquiry = useCallback(async () => {
    const data = await postDexQNAFromServer({data: inputData})
    if(data.qna_id){
      toast('Contact successfully!', { icon: '👏' })
      setInputData(initialInputData)
    }
  }, [inputData])

  return (

    <div id={'faq'}>
      <VideoBackground>
        <div className='faq-box'>
          {!isTablet && <div className='faq-box-title'>Contact us for anything!</div>}
          <form style={{ marginTop: !isTablet ? 30 : 0 }}>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' name='email' value={inputData.email} onChange={inputHandler} autoComplete='off'/><br />
            <label htmlFor='title'>Title</label>
            <input type='text' id='title' name='title' value={inputData.title} onChange={inputHandler} autoComplete='off'/><br />
            <label htmlFor='content'>Content</label>
            <textarea id='content' name='content' value={inputData.content} onChange={inputHandler} /><br />
          </form>
          <button className='button' onClick={sendInquiry} disabled={!(inputData.email && inputData.title && inputData.content)}>Send</button>
        </div>
      </VideoBackground>

    </div>
  )
}
