import useLiquidity from 'view/hooks/useLiquidity';
import React, {useMemo} from 'react'
import './Home.scss'
import moveText from 'assets/images/move-text.png'
import mainBanner from 'assets/images/main-banner.png'
import newVideoAsset from 'assets/video/bg_video.mp4'
import {Desktop, Tablet} from '../../utils/ResponsiveUtil';
import CenterizedRowBetween from '../common/container/CenterizedRowBetween';
import { parseDollar } from '../../utils/numberUtil';
import BigNumber from 'bignumber.js';
import { WEB3 } from '../../modules/Web3';
import { useSelector } from 'react-redux';
import { selectAllWallet } from '../../redux/wallet/walletSlice';

export default function Home() {
    const {currentLiquidity} = useLiquidity();
    const mainLiquidity = useMemo(() => currentLiquidity.length > 0 ? currentLiquidity[0] : {}, [currentLiquidity])
    const wrapTokens = useSelector(selectAllWallet)

    return (
        <>
            <Desktop>
                <div id={'home'}>
                    <div className="container">
                        <div className='inner-box'>
                            <img src={mainBanner} alt={'..'} className={'main-banner'}/>
                            <div className="data-wrapper">
                                {wrapTokens.length > 0 && mainLiquidity.hasOwnProperty('firstTokenUnit') &&
                                    <div className='liquidity-box'>
                                        <div className='liquidity-box-amount'>$ {
                                            parseDollar(new BigNumber(WEB3.fromWei(mainLiquidity.secondReserveValue)).dividedBy(new BigNumber(wrapTokens.find(wrapToken => wrapToken.wrap_token_id === mainLiquidity.secondTokenId)?.value))?.toFixed(2))
                                        }</div>
                                        <div className='liquidity-box-unit'>{mainLiquidity.secondTokenUnit} IN POOL
                                        </div>
                                    </div>}
                                {wrapTokens.length > 0 && mainLiquidity.hasOwnProperty('firstTokenUnit') &&
                                    <div className='liquidity-box'>
                                        <div className='liquidity-box-amount'>$ {
                                            parseDollar(new BigNumber(WEB3.fromWei(mainLiquidity.firstReserveValue)).dividedBy(new BigNumber(wrapTokens.find(wrapToken => wrapToken.wrap_token_id === mainLiquidity.firstTokenId)?.value))?.toFixed(2))
                                        }</div>
                                        <div className='liquidity-box-unit'>{mainLiquidity.firstTokenUnit} IN POOL</div>
                                    </div>}
                            </div>
                        </div>
                        <img src={moveText} alt={'..'} className={'background-text'}/>
                        <video className={'background-video'} autoPlay={true} loop={true} muted>
                            <source src={newVideoAsset} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </Desktop>
            <Tablet>
                <div id={'m-home'}>
                    <div className='inner-box'>
                        <img src={mainBanner} alt={'..'} className={'main-banner'}/>
                    </div>
                    {wrapTokens.length > 0 && mainLiquidity.hasOwnProperty('firstTokenUnit') &&
                        <CenterizedRowBetween style={{marginTop: 180}}>
                            <div className='liquidity-box'>
                                <div className='liquidity-box-amount'>${
                                  parseDollar(new BigNumber(WEB3.fromWei(mainLiquidity.secondReserveValue)).dividedBy(new BigNumber(wrapTokens.find(wrapToken => wrapToken.wrap_token_id === mainLiquidity.secondTokenId)?.value)).toFixed(2))
                                }</div>
                                <div className='liquidity-box-unit'>{mainLiquidity.secondTokenUnit} IN POOL</div>
                            </div>
                            <div className='liquidity-box'>
                                  <div className='liquidity-box-amount'>${
                                  parseDollar(new BigNumber(WEB3.fromWei(mainLiquidity.firstReserveValue)).dividedBy(new BigNumber(wrapTokens.find(wrapToken => wrapToken.wrap_token_id === mainLiquidity.firstTokenId)?.value)).toFixed(2))
                                }</div>
                                <div className='liquidity-box-unit'>{mainLiquidity.firstTokenUnit} IN POOL</div>
                            </div>
                        </CenterizedRowBetween>
                    }
                    <img src={moveText} alt={'..'} className={'background-text'}/>
                </div>
            </Tablet>
        </>
    )
}
