/* eslint-disable no-useless-catch */
import { WEB3 } from 'modules/Web3'
import { makeQuery } from '../../../utils/queryUtil'

const baseURL =  process.env.REACT_APP_HUB_SERVER_URL

export const makeTransaction = async (payload) => {
  const {
    address,
    microChainId,
    parameters,
    to,
    value,
    functionName,
    transactionPath,
    noncePath,
    gasPath,
    gasPricePath,
    token,
    additionalQuery,
    abiCode,
  } = payload || {}

  try {
    const data = await WEB3.Contract(abiCode).methods[functionName](...parameters).encodeABI();


    return {
      address,
      chainId: microChainId,
      data,
      to,
      value,
      functionName,
      ...(token && { token }),
      ...(additionalQuery && { additionalQuery }),
      loadBalancer: baseURL,
      transactionPath: transactionPath ?? `${baseURL}/api/v2/request/transaction${makeQuery({ microChainId })}`,
      ...(transactionPath && { transactionPath }),
      ...(!transactionPath && { transactionPath: `${baseURL}/api/v2/request/transaction${makeQuery({ microChainId })}` }),
      ...(!noncePath && { noncePath: `${baseURL}/api/v2/request/nonce${makeQuery({ microChainId, address })}` }),
      ...(!gasPath && { gasPath: `${baseURL}/api/v2/request/estimate-gas` }),
      ...(!gasPricePath && { gasPricePath: `${baseURL}/api/v2/micro-chains/${microChainId}/gas-price` }),
      loadBalancerURL: baseURL,
    }
  } catch (e) {
    throw (e)
  }
}
