/* eslint-disable react/prop-types */
import React from 'react'
import { default_token_icon } from '../../assets/images/imageAssets';
import CenterizedRow from '../common/container/CenterizedRow';
import './TokenItem.scss';

export default function TokenItem({ token }) {
  return <CenterizedRow className='token-item'>
    <img src={token?.icon ?? default_token_icon} alt={'token'} />
    <div className='token-item-unit'>{token?.unit ?? '-'}</div>
  </CenterizedRow>
}
