import { useMediaQuery } from "react-responsive";

export const Desktop = function ({ children }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
export const Tablet = function ({ children }) {
  const isTablet = useMediaQuery({ maxWidth: 991 });
  return isTablet ? children : null;
};
