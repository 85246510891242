/* eslint-disable camelcase */
import { ethers } from 'ethers'
import KEY from './Key'
import BigNumber from 'bignumber.js'
const Web3 = require('web3')

export const walletTransaction = (
  chainNetworkId,
  chainId,
  contractAddressQuery,
  functionName,
  tx_data,
  txNonce,
  address,
  privateKey
) => {
  const tx = {
    network_id: chainNetworkId,
    chain_id: chainId,
    from: address,
    contract: contractAddressQuery,
    function: functionName,
    nonce: parseInt(txNonce),
    data: tx_data
  }

  const txHash = KEY.MakeThash(tx)

  // tx = JSON.stringify(tx);
  const payload = {
    transaction: tx,
    tx_hash: txHash,
    pubKey: KEY.MakePublicKey(privateKey),
    signature: KEY.MakeSignature(
      txHash,
      privateKey,
      KEY.MakePublicKey(privateKey)
    )
  }
  return payload
}

const web3 = new Web3(Web3.givenProvider)
export const WEB3 = {
  privateKeyToAccount: privateKey => {
    return web3.eth.accounts.privateKeyToAccount(privateKey)
  },
  signTransaction: async ({
    nonce,
    chainId,
    to,
    data,
    value,
    gas,
    gasPrice,
    privateKey
  }) => {
    return await web3.eth.accounts.signTransaction(
      {
        nonce,
        chainId,
        to,
        data,
        value,
        gas,
        gasPrice
      },
      privateKey
    )
  },
  Contract: abiCode => {
    return new web3.eth.Contract(abiCode)
  },
  toWei: amount => {
    if (!amount) {
      return web3.utils.toWei('0')
    }
    return web3.utils.toWei(amount)
  },
  fromWei: wei => {
    try {
      return web3.utils.fromWei(wei)
    } catch (e) {
      return '-'
    }
  },
  toHex: decimal => {
    return web3.utils.toHex(decimal)
  },
  toDecimal: hex => {
    return web3.utils.toDecimal(hex)
  },
  isHex: hex => {
    return web3.utils.isHex(hex)
  },
  toBN: amount => {
    return web3.utils.toBN(amount)
  },
  add: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.add(BN2).toString()
  },
  sub: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.sub(BN2).toString()
  },
  mul: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.mul(BN2).toString()
  },
  div: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.div(BN2).toString()
  },
  gt: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.gt(BN2)
  },
  lt: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.lt(BN2)
  },
  lte: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.lte(BN2)
  }
}

export const ETHERS = {
  NumberToBytes16: number => {
    return ethers.utils.hexlify(ethers.utils.zeroPad(number, 16))
  },
  formatNetChainId: (networkId, chainId) => {
    const nId = ETHERS.NumberToBytes16(networkId)
    const cId = ETHERS.NumberToBytes16(chainId)
    return ethers.utils.hexConcat([nId, cId])
  },
  formatAddress: address => {
    if (address.length !== 42) {
      throw new Error('address length is mismatched')
    }
    return ethers.utils.hexlify(ethers.utils.zeroPad(address, 32))
  },
  getAddress: address => {
    return ethers.utils.getAddress(address)
  },
  getMaxUint256: () => {
    return ethers.constants.MaxUint256
  },
}

export const weiFor = number => {
  return new BigNumber(number).multipliedBy('1e+18').toFixed()
}

export const currencyFor = wei => {
  return new BigNumber(wei).dividedBy('1e+18').toFixed()
}
