//todo
// Response 영역에서 null 일 수 있는 값들의 확인 필요
import { makeQuery } from 'utils/queryUtil';
import { requestServer } from './requestServer'

const getCoinBalance = async (
  request: GetCoinBalanceRequest,
): Promise<GetCoinBalanceResponse> =>
    await requestServer.get({
    path: `/api/v2/request/balance`,
    query: makeQuery(request.query)
  }); 
export type GetCoinBalanceRequest = {
  query: GetCoinBalanceQuery;
};
type GetCoinBalanceQuery = {
  microChainId: number;
  address: string;
};
export type GetCoinBalanceResponse = {
  balance: string;
};

const requestCall = async (request: RequestCallRequest): Promise<object> =>
     await requestServer.post({
      path: `/api/v2/request/call`,
      query: makeQuery(request.query),
      data: request.data
    });

export type RequestCallRequest = {
  query: RequestCallQuery;
  data: RequestCallData;
};
type RequestCallQuery = {
  microChainId: number;
};

type RequestCallData = {
  to: string;
  data: string;
};



const getTransactionReceipt = async (
  request: GetTransactionReceiptRequest,
): Promise<GetTransactionReceiptResponse> =>
    await requestServer.get({
      path: `/api/v2/request/transaction/${request.params.transactionHash}/polling`,
      config: {
        headers: {
          Accept: 'application/json, text/plain, */*',
        },
      },
    });

export type GetTransactionReceiptRequest = {
  params: GetTransactionReceiptParams;
};
type GetTransactionReceiptParams = {
  transactionHash: string;
};
export type GetTransactionReceiptResponse = {
  blockHash: string;
  blockNumber: number;
  contractAddress?: string;
  cumulativeGasUsed: string;
  effectiveGasPrice: string;
  from: string;
  gasUsed: string;
  logs: Array<any>;
  logsBloom: string;
  status: boolean;
  to: string;
  transactionHash: string;
  transactionIndex: string;
  type: string;
};

export {
  requestCall as requestCallFromServer,
  getCoinBalance as getCoinBalanceFromServer,
  getTransactionReceipt as getTransactionReceiptFromServer
};
