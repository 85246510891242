/* eslint-disable prefer-regex-literals */
import _ from 'lodash'
import { WEB3 } from '../modules/Web3'

export const exceptNaN = amount => {
  if (
    _.isString(amount) &&
    _.isNumber(Number(amount)) &&
    !_.isNaN(Number(amount))
  ) {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 18
    })
      .format(amount)
      .slice(1)
  }
  return '-'
}

export const addComma = obj => {
  const regx = new RegExp(/(-?\d+)(\d{3})/)
  const bExists = obj.indexOf('.', 0)
  const strArr = obj.split('.')
  while (regx.test(strArr[0])) {
    strArr[0] = strArr[0].replace(regx, '$1,$2')
  }
  if (bExists > -1) {
    obj = strArr[0] + '.' + strArr[1]
  } else {
    obj = strArr[0]
  }
  return obj
}

export const removeComma = str => {
  const regx = new RegExp(/,/g)
  return str.replace(regx, '')
}

export const fromWeiAddCommaFixed = (number) => {
  return addComma(WEB3.fromWei(number))
}

export const validate = (s) => {
  var rgx = /^[0-9]*\.?[0-9]*$/;
  return s.match(rgx);
}
export const checkAmountValidation = (currentValue, newValue, maxLength) => {
  if (newValue === 'X') {
    if (currentValue.length === 1) {
      currentValue = '0'
    } else {
      currentValue = currentValue.slice(0, -1)
    }
  } else {
    if (maxLength <= currentValue.length) {
      return currentValue
    }
    if (newValue === '.') {
      if (currentValue.includes('.')) {
        return currentValue
      }
      currentValue = currentValue + newValue
    } else {
      if (currentValue[0] === '0' && !(currentValue[1] === '.')) {
        currentValue = currentValue.slice(0, 0)
      }
      currentValue = currentValue + newValue
    }
  }
  return currentValue
}

export const parsingFloat = (number) => {
  if (!number) {
    return '-'
  }
  const splitNumber = number.split('.')
  if (splitNumber.length > 1) {
    return splitNumber[0] + '.' + splitNumber[1].slice(0, 8)
  } else {
    return splitNumber[0]
  }
}

export const parseDollar = (str) => {
  if (!str) {
    return '-'
  }
  const num = parseInt(str);
  if ( num > 1000000000000 ) {
    return `${parseInt(num / 1000000000000)}T+`
  }
  else if ( num > 1000000000 ) {
    return `${parseInt(num / 1000000000)}B+`
  }
  else if ( num > 1000000 ) {
    return `${parseInt(num / 1000000)}M+`
  }
  else if ( num > 1000 ) {
    return `${parseInt(num / 1000)}K+`
  }
  else {
    return str
  }
}

