
import React from 'react'

const CenterizedRow = function (props) {
  const { style, ...rest } = props || {}
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxSizing: 'border-box', ...style }} {...rest}/>
  )
}

export default CenterizedRow
