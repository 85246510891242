import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Pool from './page/Pool'
import Layout from './component/app/component/Layout'
import NotFound from './page/NotFound'
import Home from './page/Home'
import Swap from './page/Swap'
import Contact from './page/Contact'

export default function RootRouter () {
  return (
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={'swap'} element={<Swap />} />
        <Route path={'pool'} element={<Pool />} />
        <Route path={'faq'} element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
};
