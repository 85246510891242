//todo
// Response 영역에서 null 일 수 있는 값들의 확인 필요
import { makeQuery } from 'utils/queryUtil';
import { contractServer } from './contractServer'

const getAbiCode = async (request: GetAbiCodeRequest): Promise<GetAbiCodeResponse> =>
  await contractServer.get({
    path: `/api/v2/contracts/address/${request.params.contractAddress}/abi-code`,
    query: makeQuery(request.query)
  });
export type GetAbiCodeRequest = {
  params: {
    contractAddress: string;
  };
  query: GetAbiCodeQuery;
};
type GetAbiCodeQuery = {
  microChainId: number;
};
export type GetAbiCodeResponse = object[];

export {
  getAbiCode as getAbiCodeFromServer,
};
