/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit'

// Adapter 영역 - 선언부
const initialState = {}

export const COIN_CONTRACT_ADDRESS =
    '0x0000000000000000000000000000000000000001';

// Slice 영역 - 선언부
const dexSlice = createSlice({
  name: 'dex',
  initialState,
  reducers: {},
  extraReducers: {},
})

// Action 영역
export const {} = dexSlice.actions

// Store 데이터 영역

// Reducer 영역
export default dexSlice.reducer
