/* eslint-disable array-callback-return */
import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {initSwap} from "../../view/component/app/thunks/appThunks";

// Adapter 영역 - 선언부
const mainNetInfo = {
  exchanger_contract_address: '',
  micro_chain_id: 0,
  network_id: 0,
  swap_pair_contract_address: '',
  swap_router_contract_address:'',
}

const wrapTokenAdapter = createEntityAdapter({
  selectId: wrapToken => wrapToken.wrap_token_id,
});
export const {
    selectAll: selectAllWrapTokens,
    selectIds: selectWrapTokensIds,
    selectById: selectWrapTokensById,
} = wrapTokenAdapter.getSelectors(
    state => state.app.wrapTokens,
);


// Slice 영역 - 선언부
const appSlice = createSlice({
  name: 'app',
  initialState: {
      mainNetInfo,
      wrapTokens: wrapTokenAdapter.getInitialState(),
  },
  reducers: {
      updateQuotes: (state, action) => {
          let newWrapTokens = [];
          action.payload.forEach((el) => {
              const wrapToken = Object.values(state.wrapTokens.entities).find(ell => el.quote_id === ell.quote_id)
              if (wrapToken) {
                  wrapToken.value = el.to_value
              }
              newWrapTokens = [...newWrapTokens, wrapToken]
          })
          wrapTokenAdapter.updateMany(state.wrapTokens, newWrapTokens);
      },
  },
  extraReducers: {
    [initSwap.fulfilled]: (state, action) => {
        state.mainNetInfo = action.payload.mainNetInfo
        wrapTokenAdapter.setMany(state.wrapTokens, action.payload.wrapTokens)
    }
  },
})

// Action 영역
export const {updateQuotes} = appSlice.actions

// Store 데이터 영역
export const mainNet_info = (state) => state.app.mainNetInfo;
export const exchanger_contract_address = (state) => state.app.mainNetInfo.exchanger_contract_address;
export const swap_pair_contract_address = (state) => state.app.mainNetInfo.swap_pair_contract_address;
export const swap_router_contract_address = (state) => state.app.mainNetInfo.swap_router_contract_address;

// Reducer 영역
export default appSlice.reducer
