import defaultTokenImage from './dollar-icon.png'

export const volare_token_icon = 'https://s3.ap-northeast-2.amazonaws.com/image.dev.eqhub.eqbr.com/wallets/currency/volare.png'
export const ethereum_token_icon = 'https://s3.ap-northeast-2.amazonaws.com/image.dev.eqhub.eqbr.com/wallets/currency/sepoliacoin.png'
export const default_token_icon = defaultTokenImage

export const getTokenImageByUnit = unit => {
    switch (unit) {
        case 'VOLR':
            return volare_token_icon;
        case 'ETH':
            return ethereum_token_icon;
        default:
            return defaultTokenImage;
    }
}