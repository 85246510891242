import { requestCallFromServer } from 'server/request/requestAPI.ts'


export const requestCall = async ({ microChainId, to, data}) => {
  return await requestCallFromServer({
    query: {
      microChainId,
    },
    data:{
      to,
      data
    }
  })
}
