import React from 'react'
import './NotFound.scss'

export default function NotFound () {
  return (
    <div id={'not-found'}>
      404
    </div>
  )
}
