/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './RemoveLiquidity.scss'
import {useDispatch, useSelector} from 'react-redux'
import {unwrapResult} from "@reduxjs/toolkit";
import toast from 'react-hot-toast'
import { WEB3 } from 'modules/Web3'
import { fromWeiAddCommaFixed } from 'utils/numberUtil'
import { accountInfo } from '../../../../redux/account/accountSlice'
import {
  getSwapPairBalanceAndAllowance,
  sendApproveTransactionForSwapPair, sendRemoveLiquidityTransaction
} from "../thunks/poolThunks";
import ExtensionListenerPrime from 'modules/EQExtension/ExtensionListnerPrime'
import CenterizedRowBetween from '../../../common/container/CenterizedRowBetween'
import BoxBackButton from '../../../atom/BoxBackButton'
import TokenItem from '../../../atom/TokenItem'
import { POOL_MAIN } from '../../../page/Pool'
import BigNumber from 'bignumber.js';

export default function RemoveLiquidity({ setComponent, component }) {
  const dispatch = useDispatch();
  const account = useSelector(accountInfo)
  const [inputPercent, setInputPercent] = useState('0')
  const [swapPair, setSwapPair] = useState(null)
  const { params } = component
  const { pair } = params

  const calculatedValues = useMemo(() => {
    if (swapPair && pair && inputPercent) {
      return {
        liquidity: WEB3.div(WEB3.mul(swapPair?.balance, inputPercent), '10000'),
        amountA: WEB3.div(WEB3.mul(pair.first_token_user_reserve_value, inputPercent), '10000'),
        amountB: WEB3.div(WEB3.mul(pair.second_token_user_reserve_value, inputPercent), '10000'),
        amountAMin: WEB3.div(WEB3.mul(pair.first_token_user_reserve_value, `${Number(inputPercent) * 9}`), '100000'),
        amountBMin: WEB3.div(WEB3.mul(pair.second_token_user_reserve_value, `${Number(inputPercent) * 9}`), '100000'),
      }
    }
    return {liquidity: '0', amountA: '0', amountB: '0', amountAMin: '0', amountBMin: '0'}
  }, [swapPair, pair, inputPercent])

  useEffect(() => {
    if (!pair.first_token.contract_address || !account.address) {
      return
    }
    const getSwapPairBalanceAndAllowance_ = async () => {
      try {
        const result = unwrapResult(await dispatch(getSwapPairBalanceAndAllowance()));
        setSwapPair(result);
      } catch (e) {
        console.error(e)
      }
    }
    getSwapPairBalanceAndAllowance_()
  }, [dispatch, pair, account])

  const onClickApprove = useCallback(async () => {
    try {
      unwrapResult(await dispatch(sendApproveTransactionForSwapPair()));
    } catch (e) {
      console.error(e)
    }
  }, [dispatch])

  const onClickRemove = useCallback(async () => {
    try {
      unwrapResult(await dispatch(sendRemoveLiquidityTransaction({pair, calculatedValues})));
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, pair, calculatedValues])

  const [transactionResponce, setTransactionResponce] = useState(null)

  useEffect(() => {
    if (transactionResponce?.success) {
      toast.success('Request transaction success...')
      setComponent({ name: POOL_MAIN, params: { txHash: transactionResponce.data } })
    }
  }, [transactionResponce, setComponent])

  const activeButton = useMemo(() => {
    if (!swapPair) return <button className='remove-liquidity-button' disabled={true} >
      Loading...
    </button>
    if(!account.address){
      return <button className='remove-liquidity-button' disabled={true}>
      Not connected
    </button>
    }
    if(WEB3.gt(calculatedValues.liquidity, swapPair.allowance)){
      return <button className='remove-liquidity-button' onClick={onClickApprove}>
      Approve to deposit Swap Pair
    </button>
    }else if(WEB3.lte(calculatedValues.liquidity, 0)){
      return <button className='remove-liquidity-button' disabled={true} >
      Not enough liquidity
    </button>
    }else{
      return <button className='remove-liquidity-button' onClick={onClickRemove} >
      Remove
    </button>
    }
  }, [account, calculatedValues, swapPair, onClickApprove, onClickRemove])

  return <>
    <ExtensionListenerPrime onGetTransactionResponce={setTransactionResponce} />
    <div className={'remove-liquidity'}>
      <div style={{ position: 'absolute', left: 0, top: 0 }}>
        <BoxBackButton onBack={() => setComponent({ name: POOL_MAIN })} />
      </div>
      <h2 className='remove-liquidity-title'>Remove liquidity</h2>

      <div>
        <div className='percentage'>
          <strong>{Number(inputPercent) / 100}%</strong>
          <CenterizedRowBetween>
            <input type='range' min='0' max='10000' style={{ backgroundSize: `${Number(inputPercent) / 100}% 100%` }}
              autoComplete='off'
              value={inputPercent} onChange={e => setInputPercent(e.nativeEvent.target.value)} />
            <button onClick={() => setInputPercent(2500)}>25%</button>&nbsp;
            <button onClick={() => setInputPercent(5000)}>50%</button>&nbsp;
            <button onClick={() => setInputPercent(7500)}>75%</button>&nbsp;
            <button onClick={() => setInputPercent(10000)}>Max</button>
          </CenterizedRowBetween>
        </div>
        <div className='remove-liquidity-receive-box' style={{ marginTop: 20 }}>
          <div className='remove-liquidity-receive-box-label'>Amount1</div>
          <CenterizedRowBetween style={{ marginTop: 16 }}>
            <TokenItem token={pair.first_token} />
            <div className='remove-liquidity-receive-amount'>{fromWeiAddCommaFixed(calculatedValues.amountA)}</div>
          </CenterizedRowBetween>
        </div>
        <div className='remove-liquidity-receive-box' style={{ marginTop: 10 }}>
          <div className='remove-liquidity-receive-box-label'>Amount2</div>
          <CenterizedRowBetween style={{ marginTop: 16 }}>
            <TokenItem token={pair.second_token} />
            <div className='remove-liquidity-receive-amount'>{fromWeiAddCommaFixed(calculatedValues.amountB)}</div>
          </CenterizedRowBetween>
        </div>
      </div>
      
      <div>
        <div className='remove-liquidity-receive-box' style={{ marginTop: 30 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='remove-liquidity-receive-box-label2'>Price</div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div className='add-liquidity-token-box-value'>1 {pair.first_token.unit} = {new BigNumber(1).dividedBy(new BigNumber(pair.liquidity_pool)).toFixed(18)} {pair.second_token.unit}</div>
              <div className='add-liquidity-token-box-value' style={{ marginTop: 10 }}>1 {pair.second_token.unit} = {pair.liquidity_pool} {pair.first_token.unit}</div>
            </div>
          </div>
        </div>
        {activeButton}
      </div>
      
    </div>
  </>
}
