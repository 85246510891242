import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {socket} from '../component/app/component/Layout'
import {getReserve} from "../component/pool/thunks/poolThunks";
export default function useLiquidity () {
  const dispatch = useDispatch();

  const [currentLiquidity, setCurrentLiquidity] = useState([]);

  const init = useCallback(async () => {
    const data = unwrapResult(await dispatch(getReserve()))
    setCurrentLiquidity(data.map(item => ({
      firstTokenCa: item.first_token_ca,
      secondTokenCa: item.second_token_ca,
      firstTokenUnit: item.first_token_unit,
      secondTokenUnit: item.second_token_unit,
      firstTokenId: item.first_wrap_token_id,
      secondTokenId: item.second_wrap_token_id,
      liquidityPool: item.liquidity_pool,
      firstReserveValue: item.first_token_reserve_value,
      secondReserveValue: item.second_token_reserve_value
    })))
  }, [dispatch])

  useEffect(() => {init()}, [init])

  const getCurrentLiquidityWithSocket = useCallback((res) => {
    setCurrentLiquidity(res)
  }, [])

  useEffect(() => {
    socket.connect();
    socket.emit("joinRoom", {
      roomName: `LiquidityCheck`,
    })
    socket.on('LiquidityCheck', getCurrentLiquidityWithSocket);
    return () => {
      socket.disconnect()
    }
  }, [getCurrentLiquidityWithSocket])

  return {currentLiquidity}
}
