import {Server, TokensFromServer, Request} from '../type';
import {apiErrorCatcher} from 'utils/CommonUtils';
import axios, {AxiosError, AxiosResponse} from 'axios';

let serverInstance = axios.create({
  baseURL: process.env.REACT_APP_WALLET_MANAGE_SERVER_URL,
  timeout: 10000,
});

const serverRequestInterceptor = (token: TokensFromServer) => {
  serverInstance.defaults.headers.common.Authorization = `Bearer ${token.access.token}`;
};
const serverResponseInterceptor = () => {
  serverInstance.interceptors.response.use(
    function (response: AxiosResponse) {
      return response;
    },
    function (error: AxiosError) {
      throw errorController(error);
    },
  );
};

const errorController = (error: AxiosError) => {
  console.error('wallet manage server error: ', error.response);
  return error;
};

const server: Server = {
  post: async function ({path, query = '', data, config}: Request) {
    try {
      return (await serverInstance.post(path + query, data, config)).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  },
  get: async function ({path, query = '', config}: Request) {
    try {
      return (await serverInstance.get(path + query, config)).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  },
  put: async function ({path, query = '', data, config}: Request) {
    try {
      return (await serverInstance.put(path + query, data, config)).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  },
  delete: async function ({path, query = '', config}: Request) {
    try {
      return (await serverInstance.delete(path + query, config)).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  },
};

export {
  server as walletManageServer,
  serverRequestInterceptor as setWalletManageServerInterceptors_request,
  serverResponseInterceptor as walletManageServerResponseInterceptor,
};
