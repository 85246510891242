import React from 'react'
import './VideoBackground.scss'
import newVideoAsset from "../../../../assets/video/bg_video.mp4";
import TextLoop from 'react-text-loop'
import { useMediaQuery } from 'react-responsive'

const VideoBackground = function ({ children }) {

  const isMaxSize = useMediaQuery({ maxWidth: 1440 });

  return (
    <div id={'video-background'}>
      {children}
      <div className="video-wrapper">
        <video className={'background-video'} playsInline={true} autoPlay={true} loop={true} muted>
          <source src={newVideoAsset} type="video/mp4" />
        </video>
        {
          !isMaxSize ?
            <div className="text-loop-wrapper">
              <TextLoop>
                <div className='loop-row-wrapper'>PILE UP YOUR ASSET</div>
                <div className='loop-row-wrapper'>WITH ACROSWAP</div>
                <div className='loop-row-wrapper'>RISE ABOVE THE LIMITS</div>
              </TextLoop>
            </div> : null}
      </div>
    </div>

  )
}

export default VideoBackground
