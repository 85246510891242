/* eslint-disable camelcase */
import Web3 from 'web3'

const web3 = new Web3()

export const WEB3 = {
  privateKeyToAccount: privateKey => {
    return web3.eth.accounts.privateKeyToAccount(privateKey)
  },
  signTransaction: async ({
    nonce,
    chainId,
    to,
    data,
    value,
    gas,
    gasPrice,
    privateKey
  }) => {
    return await web3.eth.accounts.signTransaction(
      {
        nonce,
        chainId,
        to,
        data,
        value,
        gas,
        gasPrice
      },
      privateKey
    )
  },
  Contract: (abiCode) => {
    return new web3.eth.Contract(abiCode)
  },
  hexToBytes: (hex) => {
    return web3.utils.hexToBytes(hex)
  },
  bytesToHex: (bytes) => {
    return web3.utils.bytesToHex(bytes)
  },
  toWei: (amount) => {
    if (!amount) {
      return web3.utils.toWei('0')
    }
    return web3.utils.toWei(amount)
  },
  fromWei: (wei) => {
    try {
      return web3.utils.fromWei(wei)
    } catch (e) {
      return '-'
    }
  },
  toHex: (decimal) => {
    return web3.utils.toHex(decimal)
  },
  toDecimal: (hex) => {
    return web3.utils.toDecimal(hex)
  },
  isHex: (hex) => {
    return web3.utils.isHex(hex)
  },
  toBN: (amount) => {
    return web3.utils.toBN(amount)
  },
  add: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.add(BN2).toString()
  },
  sub: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.sub(BN2).toString()
  },
  mul: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.mul(BN2).toString()
  },
  div: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.div(BN2).toString()
  },
  addElements: (el) => {
    const result = el.reduce((acc, cur) => {
      const BN_acc = web3.utils.toBN(acc)
      const BN_cur = web3.utils.toBN(cur)
      return BN_acc.add(BN_cur).toString()
    })
    return result
  },
  subElements: (el) => {
    const result = el.reduce((acc, cur) => {
      const BN_acc = web3.utils.toBN(acc)
      const BN_cur = web3.utils.toBN(cur)
      return BN_acc.sub(BN_cur).toString()
    })
    return result
  },
  mulElements: (el) => {
    const result = el.reduce((acc, cur) => {
      const BN_acc = web3.utils.toBN(acc)
      const BN_cur = web3.utils.toBN(cur)
      return BN_acc.mul(BN_cur).toString()
    }, 1)
    return result
  },
  divElements: (el) => {
    const result = el.reduce((acc, cur) => {
      const BN_acc = web3.utils.toBN(acc)
      const BN_cur = web3.utils.toBN(cur)
      return BN_acc.div(BN_cur).toString()
    }, 1)
    return result
  },
  gt: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.gt(BN2)
  },
  lt: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)

    return BN1.lt(BN2)
  },
  lte: (amount1, amount2) => {
    const BN1 = web3.utils.toBN(amount1)
    const BN2 = web3.utils.toBN(amount2)
    return BN1.lte(BN2)
  },
  // account
  createAccount: () => {
    return web3.eth.accounts.create()
  },
  encryptKeystore: (privateKey, password) => {
    return web3.eth.accounts.encrypt(privateKey, password)
  },
  decryptKeystore: (keystoreJson, password) => {
    return web3.eth.accounts.decrypt(keystoreJson, password)
  },
}
