import {createAsyncThunk} from "@reduxjs/toolkit";
import {getReserveFromServer,} from "../../../../server/dex/dexAPI";
import {getAbiCodeFromServer} from "../../../../server/contract/contractAPI";
import {accountInfo} from "../../../../redux/account/accountSlice";
import {
    getTransactionReceiptFromServer,
    requestCallFromServer
} from "../../../../server/request/requestAPI";
import {WEB3} from "../../../../modules/Web3";
import {mainNet_info} from "../../../../redux/app/appSlice";
import { selectAllWallet } from "../../../../redux/wallet/walletSlice";
import {requestTransactionToExtension} from "../../../../modules/EQExtension/utils/messageUtils";
import {makeTransaction} from "../../../../modules/EQExtension/utils/requestTransaction";
import {ETHERS} from "../../../../modules/transaction";
import {requestCall} from "../../account/thunks/action";

export const getTokenBalance = createAsyncThunk(
    'pool/getTokenBalance',
    async ({wrapToken}, { getState }) => {
        const {address} = accountInfo(getState())
        if(!address){
            return {...wrapToken, balance: 0}
        }
        const mainNet = mainNet_info(getState());
        const abiCode = await getAbiCodeFromServer({
            params: {
                contractAddress: wrapToken.contract_address,
            },
            query: {
                microChainId:
                mainNet.micro_chain_id,
            },
        })
        const balanceResult = await requestCallFromServer({
            query: {
                microChainId: mainNet.micro_chain_id,
            },
            data:{  to: wrapToken.contract_address,
                data: WEB3.Contract(abiCode).methods.balanceOf(address).encodeABI()}
        })
        return {
            ...wrapToken,
            balance: balanceResult[0],
        }
    },
);

export const getReserve = createAsyncThunk(
    'pool/getReserve',
    async (_, { getState }) => {
        const {address} = accountInfo(getState())
        const wrapTokens = selectAllWallet(getState())
        const data = await getReserveFromServer({query: {isMine: Boolean(address) ? 1 : 0, ...(address && {address})}});
        return data.map(item => ({
            ...item,
            first_token: wrapTokens.find(wrapToken => wrapToken.wrap_token_id === item.first_wrap_token_id) ?? {},
            second_token: wrapTokens.find(wrapToken => wrapToken.wrap_token_id === item.second_wrap_token_id) ?? {}
        }))
    },
);

export const getReceipts = createAsyncThunk(
    'pool/getReceipts',
    async ({transactionHash}, { getState }) => {
        const result = await getTransactionReceiptFromServer({
            params: {
                transactionHash
            }
        })
        return result
    },
);

export const getTokenDataForAdd = createAsyncThunk(
    'pool/getTokenDataForAdd',
    async ({pair}, { getState }) => {
        const {address} = accountInfo(getState())
        const mainNet = mainNet_info(getState());
        const wrapTokens = selectAllWallet(getState())
        const token1AbiData = await getAbiCodeFromServer({
            params: {
                contractAddress: pair.first_token.contract_address
            },
            query: {
                microChainId: mainNet.micro_chain_id,
            }
        })
        const token2AbiData = await getAbiCodeFromServer({
            params: {
                contractAddress: pair.second_token.contract_address
            },
            query: {
                microChainId: mainNet.micro_chain_id,
            }
        })
        const token1Result = await requestCallFromServer({
            query: {
                microChainId: mainNet.micro_chain_id,
            },
            data:{
                to: pair.first_token.contract_address,
                data: WEB3.Contract(token1AbiData).methods.balanceOf(address).encodeABI()
            }
        })
        const token2Result = await requestCallFromServer({
            query: {
                microChainId: mainNet.micro_chain_id,
            },
            data:{    
                to: pair.second_token.contract_address,
                data: WEB3.Contract(token2AbiData).methods.balanceOf(address).encodeABI()
            }
        })
        const token1AllowanceData = await requestCallFromServer({
            query: {
                microChainId: mainNet.micro_chain_id,
            },
            data:{
                to: pair.first_token.contract_address,
                data: WEB3.Contract(token1AbiData).methods.allowance(address, mainNet.swap_router_contract_address).encodeABI()}
        })
        const token2AllowanceData = await requestCallFromServer({
            query: {
                microChainId: mainNet.micro_chain_id},
                data:{
                   to: pair.second_token.contract_address,
                  data: WEB3.Contract(token2AbiData).methods.allowance(address, mainNet.swap_router_contract_address).encodeABI()
            }
        })
        const tokensData = [
            {
                ca: pair.first_token.contract_address,
                unit: pair.first_token.unit,
                abi: token1AbiData,
                balance: token1Result[0],
                allowance: token1AllowanceData[0],
                icon: wrapTokens.find(wrapToken => wrapToken.wrap_token_id === pair.first_token.wrap_token_id).icon
            },
            {
                ca: pair.second_token.contract_address,
                unit: pair.second_token.unit,
                abi: token2AbiData,
                balance: token2Result[0],
                allowance: token2AllowanceData[0],
                icon: wrapTokens.find(wrapToken => wrapToken.wrap_token_id === pair.second_token.wrap_token_id).icon
            }
        ]
        return tokensData
    },
);

export const sendApproveTransactionByIndex = createAsyncThunk(
    'pool/sendApproveTransactionByIndex',
    async ({tokens, index}, { getState }) => {
        const userInfo = accountInfo(getState());
        const mainNet = mainNet_info(getState());
        requestTransactionToExtension(await makeTransaction({
            address: userInfo.address,
            microChainId: mainNet.micro_chain_id,
            contractAddress: tokens[index].ca,
            parameters: [
                mainNet.swap_router_contract_address,
                ETHERS.getMaxUint256()
            ],
            to: tokens[index].ca,
            functionName: 'approve',
            abiCode: tokens[index].abi,
            loadBalancerURL: ''
        }))

    },
);

export const sendAddLiquidityTransaction = createAsyncThunk(
    'pool/sendAddLiquidityTransaction',
    async ({pair, inputData}, { getState }) => {
        const userInfo = accountInfo(getState());
        const mainNet = mainNet_info(getState());
        const swapRouterAbi = await getAbiCodeFromServer({
            params: {contractAddress: mainNet.swap_router_contract_address},
            query: {microChainId: mainNet.micro_chain_id}
        })
        requestTransactionToExtension(await makeTransaction({
            address: userInfo.address,
            microChainId: mainNet.micro_chain_id,
            contractAddress: mainNet.swap_router_contract_address,
            parameters: [
                pair.first_token.contract_address,
                pair.second_token.contract_address,
                inputData.tokenBalance1,
                inputData.tokenBalance2,
                WEB3.div(WEB3.mul(inputData.tokenBalance1, '9'), '10'),
                WEB3.div(WEB3.mul(inputData.tokenBalance2, '9'), '10'),
                userInfo.address,
                Date.now() + 10 * 60 * 1000
            ],
            to: mainNet.swap_router_contract_address,
            functionName: 'addLiquidity',
            transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}/api/v2/dex/add-liquidity?microChainId=${mainNet.micro_chain_id}`,
            // token: '1',
            additionalQuery: {
                body: {
                    address: userInfo.address,
                    firstWrapTokenId: pair.first_token.wrap_token_id,
                    secondWrapTokenId: pair.second_token.wrap_token_id,
                    firstWrapTokenAmount: inputData.tokenBalance1,
                    secondWrapTokenAmount: inputData.tokenBalance2
                }
            },
            abiCode: swapRouterAbi,
            loadBalancerURL:'',
        }))
    }
);

export const getSwapPairBalanceAndAllowance = createAsyncThunk(
    'pool/getSwapPairBalanceAndAllowance',
    async (_, { getState }) => {
        const userInfo = accountInfo(getState());
        const mainNet = mainNet_info(getState());
        const swapPairAbi = await getAbiCodeFromServer({ params: { contractAddress: mainNet.swap_pair_contract_address }, query: { microChainId: mainNet.micro_chain_id } })
        const swapPairBalanceData = await requestCall({
            microChainId: mainNet.micro_chain_id,
            to: mainNet.swap_pair_contract_address,
            data: WEB3.Contract(swapPairAbi).methods.balanceOf(userInfo.address).encodeABI()
        })
        const swapPairAllowanceData = await requestCall({
            microChainId: mainNet.micro_chain_id,
            to: mainNet.swap_pair_contract_address,
            data: WEB3.Contract(swapPairAbi).methods.allowance(userInfo.address, mainNet.swap_router_contract_address).encodeABI()
        })
        return {
            balance: swapPairBalanceData[0],
            allowance: swapPairAllowanceData[0]
        }
    }
);

export const sendApproveTransactionForSwapPair = createAsyncThunk(
    'pool/sendApproveTransactionForSwapPair',
    async (_, { getState }) => {
        const userInfo = accountInfo(getState());
        const mainNet = mainNet_info(getState());
        const swapPairAbi = await getAbiCodeFromServer({ params: { contractAddress: mainNet.swap_pair_contract_address }, query: { microChainId: mainNet.micro_chain_id } })
        requestTransactionToExtension(await makeTransaction({
            address: userInfo.address,
            microChainId: mainNet.micro_chain_id,
            contractAddress: mainNet.swap_pair_contract_address,
            parameters: [
                mainNet.swap_router_contract_address,
                ETHERS.getMaxUint256()
            ],
            to: mainNet.swap_pair_contract_address,
            functionName: 'approve',
            abiCode: swapPairAbi,
            loadBalancerURL: ''
        }))
        return true;
    },
);

export const sendRemoveLiquidityTransaction = createAsyncThunk(
    'pool/sendRemoveLiquidityTransaction',
    async ({pair, calculatedValues}, { getState }) => {
        const userInfo = accountInfo(getState());
        const mainNet = mainNet_info(getState());
        const swapRouterAbi = await getAbiCodeFromServer({
            params: {contractAddress: mainNet.swap_router_contract_address},
            query: {microChainId: mainNet.micro_chain_id}
        })
        requestTransactionToExtension(await makeTransaction({
            address: userInfo.address,
            microChainId: mainNet.micro_chain_id,
            contractAddress: mainNet.swap_router_contract_address,
            parameters: [
                pair.first_token.contract_address,
                pair.second_token.contract_address,
                calculatedValues.liquidity,
                calculatedValues.amountAMin,
                calculatedValues.amountBMin,
                userInfo.address,
                Date.now() + 10 * 60 * 1000
            ],
            to: mainNet.swap_router_contract_address,
            functionName: 'removeLiquidity',
            transactionPath: `${process.env.REACT_APP_HUB_SERVER_URL}/api/v2/dex/remove-liquidity?microChainId=${mainNet.micro_chain_id}`,
            // token: '1',
            additionalQuery: {
                body: {
                    address: userInfo.address,
                    firstWrapTokenId: pair.first_token.wrap_token_id,
                    secondWrapTokenId: pair.second_token.wrap_token_id,
                    liquidity: calculatedValues.liquidity,
                    firstWrapTokenAmountMin: calculatedValues.amountAMin,
                    secondWrapTokenAmountMin: calculatedValues.amountBMin
                }
            },
            abiCode: swapRouterAbi,
            loadBalancerURL:'',
        }))
    }
);
