import { makeQuery } from '../..//utils/queryUtil';
import {walletManageServer} from './walletManageServer';
//todo
// Response 영역에서 null 일 수 있는 값들의 확인 필요
const getNetworks = async (
  request: GetNetworksRequest,
): Promise<GetNetworksResponse> =>
  await walletManageServer.get({
    path: '/api/v2/wallet-manage/networks',
    query: makeQuery(request.query),
  });
export type GetNetworksRequest = {
  query: GetNetworksQuery;
};
type GetNetworksQuery = {
  networkId: number;
  isDefault?: boolean;
  isExternal?: boolean;
};
export type GetNetworksResponse = NetworkFromServer[];
type NetworkFromServer = {
  network_id: number;
  network_label: string;
};

const getMicroChains = async (
  request: GetMicroChainsRequest,
): Promise<GetMicroChainsResponse> =>
  await walletManageServer.get({
    path: '/api/v2/wallet-manage/micro-chains',
    query: makeQuery(request.query),
  });
export type GetMicroChainsRequest = {
  query: GetMicroChainQuery;
};
type GetMicroChainQuery = {
  networkId: number;
  microChainId: number;
  isDefault: boolean;
  isExternal?: boolean;
};
export type GetMicroChainsResponse = MicroChainFromServer[];
type MicroChainFromServer = {
  network_id: number;
  micro_chain_id: number;
  network_label: string;
  micro_chain_label: string;
  background_image?: string;
  load_balancer: string;
  is_default: boolean;
};

const getCurrency = async (
  request: GetCurrencyRequest,
): Promise<GetCurrencyResponse> =>
  await walletManageServer.get({
    path: '/api/v2/wallet-manage/micro-chain-currency',
    query: makeQuery(request.query),
  });
export type GetCurrencyRequest = {
  query: GetCurrencyQuery;
};
type GetCurrencyQuery = {
  microChainId: number;
  microChainCurrencyIds: number[];
  type: number[];
  originOwnerId: number;
  contractAddress: string[];
};
export type GetCurrencyResponse = CurrencyFromServer[];
type CurrencyFromServer = {
  contract_address: string;
  contract_id?: number;
  currency_id: number;
  image: string;
  micro_chain_currency_id: number;
  micro_chain_id: number;
  name: string;
  origin_micro_chain_id: number;
  origin_network_id: number;
  origin_owner_id?: number;
  type: number;
  unit: string;
};

export {
  getNetworks as getNetworksFromServer,
  getMicroChains as getMicroChainsFromServer,
  getCurrency as getCurrencyFromServer,
};
