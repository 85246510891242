import React from 'react'
import './Button.scss'

function Button (props) {
  return (
        <button type={'button'} className={'main-button'} {...props} />
  )
}

export default Button
