/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import './PoolMain.scss'
import { fromWeiAddCommaFixed } from 'utils/numberUtil'
import CenterizedRow from 'view/common/container/CenterizedRow'
import CenterizedRowBetween from '../../../common/container/CenterizedRowBetween'
import { POOL_ADD_LIQUIDITY, POOL_ADD_LIQUIDITY_PRIME, POOL_REMOVE_LIQUIDITY } from '../../../page/Pool'
import { accountInfo } from '../../../../redux/account/accountSlice'
import { useMediaQuery } from 'react-responsive'
import TokenItem from '../../../atom/TokenItem'
import BigNumber from 'bignumber.js'


export default function PoolMain({ setComponent, component, pairs, getReserveData }) {
  const account = useSelector(accountInfo);
  const isConnect = useMemo(() => Boolean(account?.address), [account])
  const isTablet = useMediaQuery({ maxWidth: 992 });

  const primeAddButton = useCallback((item, index=0) => <button key={`prime_${index}`}
    className={'pool-main-action-button'}
    onClick={() => setComponent({ name: POOL_ADD_LIQUIDITY_PRIME, params: { pair: {...(item && {...item})} } })}>
    PRIMARY ADD LIQUIDITY
  </button>, [setComponent])

  const renderItem = useCallback((item, index) => {

    if(item.first_token_reserve_value === '0' && item.second_token_reserve_value === '0'){
      return primeAddButton(item, index)
    }

    return <div className='pool-list' key={`pool_${index}`}>
      <div className='pool-inner-box'>
        <CenterizedRowBetween>
          <div style={{ width: '100%' }}>
            <CenterizedRow>
              <img src={item.first_token.icon} alt={'token'} className='pool-token-image' />
              <img src={item.second_token.icon} alt={'token'} className='pool-token-image'
                style={{ marginLeft: -4, marginRight: 10 }} />
              <div>{item.first_token.unit} / {item.second_token.unit}</div>
              <div className='share'>0.3%</div>
            </CenterizedRow>

            <div className='liquidity-label'>All liquidity</div>
            <CenterizedRowBetween className={'liquidity-row'}>
              <TokenItem token={item.first_token} />
              {fromWeiAddCommaFixed(item.first_token_reserve_value)}
            </CenterizedRowBetween>
            <CenterizedRowBetween className={'liquidity-row'}>
              <TokenItem token={item.second_token} />
              {fromWeiAddCommaFixed(item.second_token_reserve_value)}
            </CenterizedRowBetween>

            {isConnect && item.first_token_user_reserve_value && <>
              <div className='liquidity-label'>My liquidity</div>
              <CenterizedRowBetween className={'liquidity-row'}>
                <TokenItem token={item.first_token} />
                {fromWeiAddCommaFixed(item.first_token_user_reserve_value)}
              </CenterizedRowBetween>
              <CenterizedRowBetween className={'liquidity-row'}>
                <TokenItem token={item.second_token} />
                {fromWeiAddCommaFixed(item.second_token_user_reserve_value)}
              </CenterizedRowBetween>
              {item.first_token_reserve_value !== '0' && item.first_token_user_reserve_value !== '0' && <CenterizedRowBetween className={'liquidity-row'}>
                <div className={'liquidity-row-label'}>share</div>
                {new BigNumber(item.first_token_user_reserve_value).dividedBy(new BigNumber(item.first_token_reserve_value)).multipliedBy(100).toFixed(2)}%
              </CenterizedRowBetween>}
              <CenterizedRowBetween style={{ marginTop: 8 }}>
                <button className={'pool-main-action-button reverse'} onClick={() => setComponent({ name: POOL_REMOVE_LIQUIDITY, params: { pair: item } })} style={{ marginRight: 8 }}>Remove</button>
                <button className={'pool-main-action-button'} onClick={() => setComponent({ name: POOL_ADD_LIQUIDITY, params: { pair: item } })} style={{ marginLeft: 8 }}>Add</button>

              </CenterizedRowBetween>
            </>}
          </div>
        </CenterizedRowBetween>
      </div>
    </div>
  }, [isConnect, setComponent, primeAddButton])

  return (
    <div className='pool-main'>
      {!isTablet && <div className='pool-main-title'>All liquidity</div>}
      {Boolean(pairs) && pairs?.map(renderItem)}
      {pairs?.length === 0 && primeAddButton()}
    </div>
  )
}
