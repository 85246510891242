import { makeQuery } from 'utils/queryUtil';
import { swapServer } from './swapsServer';
import {WrapTokenFromServer} from './type';

const getSwapPairs = async (
    request: GetSwapPairsRequest,
): Promise<GetSwapPairsResponse> =>
    await swapServer.get({
        path: '/api/v1/swaps/swap-pairs',
        query: makeQuery(request?.query),
    });
export type GetSwapPairsRequest = {
    query: GetSwapPairsQuery;
};
type GetSwapPairsQuery = {
    wrapTokenId: number;
};
export type GetSwapPairsResponse = number[];

const getWrapTokens = async (
    request: GetWrapTokensRequest,
): Promise<GetWrapTokensResponse> =>
    await swapServer.get({
        path: '/api/v1/swaps/wrap-tokens',
        query: makeQuery(request?.query),
    });
export type GetWrapTokensRequest = {
    query: GetWrapTokensQuery;
};
type GetWrapTokensQuery = {
    wrapTokenIdList?: number[];
    microChainId?: number;
    contractAddress?: string;
    ownerMicroChainId?: number;
    ownerMicroChainCurrencyId?: number;
};
export type GetWrapTokensResponse = WrapTokenFromServer[];

const getMainNetInfo = async (): Promise<GetMainNetInfoResponse> =>
    await swapServer.get({
        path: '/api/v1/swaps/main-net',
    });
export type GetMainNetInfoResponse = {
    exchanger_contract_address: string;
    swap_router_contract_address: string;
    network_id: number;
    micro_chain_id: number;
};

export {
    getWrapTokens as getWrapTokensFromServer,
    getSwapPairs as getSwapPairsFromServer,
    getMainNetInfo as getMainNetInfoFromServer,
};
